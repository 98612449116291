import { configureStore } from "@reduxjs/toolkit";
import { teamsSlice } from "./teamsSlice";
import { playersSlice } from "./playersSlice";
import filtersSlice from "./filtersSlice";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

export const store = configureStore({
  reducer: {
    teams: teamsSlice.reducer,
    players: playersSlice.reducer,
    filters: filtersSlice.reducer,
  },
});

// https://redux-toolkit.js.org/tutorials/typescript
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

type DispatchFunc = () => AppDispatch;
export const useAppDispatch: DispatchFunc = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
