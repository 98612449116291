import { imageColumns } from "../../constants";
import { SFlex } from "../../styles";
import { IPlayer } from "../../types";
import Image from "../Image";

const PlayerChemistry = ({ player }: { player: IPlayer }) => {
  return (
    <SFlex
      $alignItems="center"
      gap="8px"
      style={{ paddingLeft: "40px", width: "100%" }}
    >
      <span>CHEMISTRY</span>
      <SFlex $alignItems="center" gap="4px">
        <Image
          title={player.playerChemistry}
          src={`${imageColumns.playerChemistry}${player.playerChemistry}.png`}
          style={{ height: "30px" }}
        />
        <p>{player.playerChemistry}</p>
      </SFlex>
    </SFlex>
  );
};

export default PlayerChemistry;
