import axios from "axios";
import { BASE_URL } from "../constants";

export const fetchPlayers = async (params: any) => {
  const { data } = await axios.get(
    `${BASE_URL}/player/${params.isPitchers ? "pitchers" : "all"}`,
    {
      params,
    }
  );

  return data;
};

export const fetchSinglePlayer = async (params: {
  playerSlug: string;
  teamSlug?: string;
  teamDirectory?: string;
}) => {
  const { data } = await axios.get(`${BASE_URL}/player/single`, {
    params,
  });
  return data.player;
};

export const fetchPlayerBio = async (params: { playerId: number }) => {
  const { data } = await axios.get(`${BASE_URL}/player/bio`, {
    params,
  });

  return {
    bios: data.bio,
    emotions: data.emotions,
  };
};
