import { useRef } from "react";
import Image from "../../Image";
import { ITeam } from "../../../types";
import { SFlex, SLinkButton } from "../../../styles";
import { BLANK_TEAM_IMG, IMAGE_BASE_URL } from "../../../constants";
import useLoading from "../../../hooks/useLoading";
import BaseballLoader from "../../BaseballLoader";
import { useAppDispatch } from "../../../state/store";
import { updateTeamLogo } from "../../../state/teamsSlice";

type TProps = {
  team: ITeam;
};

const TeamLogoUploader = ({ team }: TProps) => {
  const dispatch = useAppDispatch();
  const { loading, setLoading } = useLoading();
  const uploadRef = useRef<HTMLInputElement>();

  const submitImage = async (file) => {
    setLoading(true);
    const formData = new FormData();

    if (!file) {
      return;
    }

    formData.append("files", file[0]);
    await dispatch(
      updateTeamLogo({ formData, teamDirectory: team.teamDirectory })
    );
    setLoading(false);
  };

  return (
    <SFlex $alignItems="end">
      {loading ? (
        <BaseballLoader />
      ) : (
        <Image
          style={{ height: "150px" }}
          src={
            team.teamImages.team_logo
              ? `${IMAGE_BASE_URL}/${team.teamImages.team_logo}`
              : BLANK_TEAM_IMG
          }
        />
      )}
      <input
        type="file"
        name="files"
        ref={uploadRef}
        style={{ display: "none" }}
        onChange={(event: any) => {
          submitImage(event.target.files);
        }}
      />
      <SLinkButton
        type="button"
        disabled={loading}
        onClick={() => {
          uploadRef?.current.click();
        }}
      >
        {loading ? "Please wait..." : "Upload new Logo"}
      </SLinkButton>
    </SFlex>
  );
};

export default TeamLogoUploader;
