import React, { createContext, useContext } from "react";
import { IAppContext } from "./types";
import { useMatch } from "react-router-dom";
import { useAuth } from "./hooks/useAuth";

const AppContext = createContext<IAppContext>({
  allTeams: undefined,
  isPitchers: false,
  registerUser: undefined,
  loginUser: undefined,
  getSession: undefined,
  logoutUser: undefined,
  isLoggedIn: false,
  tokens: undefined,
});

const AppContextProvider = ({ children }: { children: React.ReactNode }) => {
  const isPitchers = !!useMatch("/pitchers/*");
  const authHook = useAuth();

  return (
    <AppContext.Provider
      value={{
        ...authHook,
        isPitchers,
        tokens: authHook.getSession(),
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const useAppContext = () => useContext(AppContext);

export { AppContextProvider, useAppContext };
