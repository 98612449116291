import axios from "axios";
import jwtDecode from "jwt-decode";

import { BASE_URL, SESSION_VALUE } from "../constants";
import { Ttokens } from "../types";
import { useEffect, useState } from "react";

export const isSessionValid = (tokens: Ttokens): boolean => {
  if (!tokens?.accessToken) {
    return false;
  }

  const currentDate = new Date();
  const decodedToken: any = jwtDecode(tokens.accessToken);
  return currentDate.getTime() < decodedToken.exp * 1000;
};

export const useAuth = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    verifySession();
  }, [isLoggedIn]);

  const registerUser = async ({
    email,
    password,
    firstName,
    lastName,
  }: {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
  }) => {
    try {
      const { data } = await axios.post(`${BASE_URL}/auth/register`, {
        email,
        password,
        firstName,
        lastName,
      });
      setSession(data);
      return data;
    } catch (err) {
      console.log(err, "Registration error");
    }
  };

  const loginUser = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    try {
      const { data } = await axios.post(`${BASE_URL}/auth/login`, {
        email,
        password,
      });
      setSession(data);
      return data;
    } catch (err) {
      console.log(err, "login error");
    }
  };

  const logoutUser = () => {
    localStorage.removeItem(SESSION_VALUE);
    setIsLoggedIn(false);
  };

  const refreshSession = async (refreshToken: string) => {
    try {
      const { data } = await axios.post(`${BASE_URL}/auth/refreshToken`, {
        refreshToken,
      });
      setSession(data);
      return data;
    } catch (err) {
      setIsLoggedIn(false);
    }
  };

  const verifySession = async () => {
    const tokens = getSession();
    if (isSessionValid(tokens)) {
      setIsLoggedIn(true);
      return;
    }

    const refreshedTokes = await refreshSession(tokens.refreshToken);
    setIsLoggedIn(!!refreshedTokes?.accessToken);
  };

  const getSession = () => {
    const token = localStorage.getItem(SESSION_VALUE);
    return token ? JSON.parse(token) : {};
  };

  const setSession = (tokens: Ttokens) => {
    if (tokens?.accessToken && tokens?.refreshToken) {
      const { accessToken, refreshToken } = tokens;
      localStorage.setItem(
        SESSION_VALUE,
        JSON.stringify({ accessToken, refreshToken })
      );
      setIsLoggedIn(true);
    } else {
      console.log(tokens, "NOT VALID FOR SESSION");
      setIsLoggedIn(false);
    }
  };

  return {
    registerUser,
    loginUser,
    getSession,
    logoutUser,
    isLoggedIn,
  };
};
