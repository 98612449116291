import { useEffect, useState } from "react";
import Image from "../../Image";
import { ITeam } from "../../../types";
import DropZoneUploader from "./DropZoneUploader";
import { SBox, SFlex } from "../../../styles";
import { loadImageDataAsync, wait } from "../../../utilities/helpers";
import BaseballLoader from "../../BaseballLoader";

type TProps = {
  label: string;
  upload: any;
  teamDirectory?: string | null;
  setTeam: (item: ITeam) => void;
};

let msgInterval;
const LOADING_MESSAGES = [
  "Getting started...",
  "Figuring out their names...",
  "Taking team pictures...",
  "Gathering the stats...",
  "Finishing up...",
];

const TeamUploader = ({ label, upload, teamDirectory, setTeam }: TProps) => {
  const [files, setFiles] = useState<Blob[] | undefined>();
  const [loading, setLoading] = useState(false);
  const [loadingMsgIndex, setLoadingMsgIndex] = useState(0);
  const [previewSrc, setPreviewSrc] = useState<any[] | undefined>();

  useEffect(() => {
    if (!files) {
      return;
    }

    const readFiles = async () => {
      const asyncFiles = Array.from(files).map((file) => {
        return loadImageDataAsync(file);
      });
      const resp = await Promise.all(asyncFiles);
      setPreviewSrc(resp);
    };

    readFiles();
  }, [files]);

  const submitImage = async () => {
    setLoading(true);
    const formData = new FormData();

    if (!files) {
      return;
    }

    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
    }

    const data = await upload(formData, teamDirectory);

    await wait(2000); // give AWS images to get ready

    setPreviewSrc(undefined);
    // @ts-ignore
    setTeam((prev) => ({
      ...prev,
      name: "",
      teamDirectory: data.teamDirectory,
      players: data.playerData,
      teamImages: data.teamImages,
    }));
    setLoading(false);
  };

  useEffect(() => {
    if (loading && loadingMsgIndex < LOADING_MESSAGES.length - 1) {
      msgInterval = setInterval(() => {
        setLoadingMsgIndex(loadingMsgIndex + 1);
      }, 6000);
    } else {
      console.log("interval ended!");
      clearInterval(msgInterval);
    }

    return () => clearInterval(msgInterval);
  }, [loading, loadingMsgIndex]);

  if (loading) {
    return (
      <SBox flex={1}>
        <h1>{LOADING_MESSAGES[loadingMsgIndex]}</h1>
        <BaseballLoader />
      </SBox>
    );
  }

  return (
    <SBox flex={1}>
      <form
        id="image-form"
        onSubmit={(event) => {
          event.preventDefault();
          submitImage();
        }}
      >
        <DropZoneUploader label={label} setFiles={setFiles} />
        <input type="submit" value="Submit" disabled={!files} />
      </form>
      {previewSrc && (
        <SFlex gap="10px">
          {previewSrc.map((imgSrc) => (
            <Image key={imgSrc.slice(-10)} src={imgSrc as string} />
          ))}
        </SFlex>
      )}
    </SBox>
  );
};

export default TeamUploader;
