import { SFlex } from "../styles";
import Image from "./Image";

const BaseballLoader = ({ title }: { title?: string }) => {
  return (
    <SFlex
      justify="center"
      $alignItems="center"
      direction="column"
      style={{ height: "100%", width: "100%" }}
    >
      <Image
        alt="loading baseball"
        src={`${process.env.PUBLIC_URL}/baseball-ball.gif`}
        style={{
          height: "100px",
        }}
      />
      {title && <h3>{title}</h3>}
    </SFlex>
  );
};

export default BaseballLoader;
