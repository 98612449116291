import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { TFilterState, TPlayerState } from "./stateTypes";
import { fetchPlayers, fetchSinglePlayer } from "../api/playerApi";

const PLAYER_SIZE = 100;

const initialState: TPlayerState = {
  loading: false,
  hasMore: false,
  page: 0,
  count: 0,
  players: undefined,
  singlePlayer: undefined,
  sort: {
    sortAttr: "teamId",
    isAsc: true,
  },
};

export const getSinglePlayer = createAsyncThunk(
  "getSinglePlayer",
  async (params: {
    playerSlug: string;
    teamSlug?: string;
    teamDirectory?: string;
  }) => {
    const data = await fetchSinglePlayer(params);
    return data;
  }
);

export const getPlayers = createAsyncThunk(
  "getPlayers",
  async (
    params: {
      isPitchers?: boolean;
      shouldReset?: boolean;
      apiFilters: any;
      nameFilter: any;
    },
    { getState }
  ) => {
    const state = getState() as {
      players: TPlayerState;
      filters: TFilterState;
    };
    const { page, sort } = state.players;
    const requestParams = {
      skip: params?.shouldReset ? 0 : PLAYER_SIZE * page,
      take: PLAYER_SIZE,
      sortAttr: sort.sortAttr,
      isAsc: sort.isAsc,
      nameQuery: params.nameFilter,
      isPitchers: !!params.isPitchers,
      ...params.apiFilters,
    };
    const players = await fetchPlayers(requestParams);
    return players;
  }
);

export const playersSlice = createSlice({
  name: "playersSlice",
  initialState,
  reducers: {
    sortPlayers: (state, action) => {
      const { sortAttr } = action.payload;
      const isChangedSortAttr = sortAttr !== state.sort.sortAttr;
      const isAsc = isChangedSortAttr ? state.sort.isAsc : !state.sort.isAsc;
      state.sort = { sortAttr, isAsc };
    },
  },
  extraReducers(builder) {
    builder.addCase(getPlayers.fulfilled, (state, action) => {
      const data = action.payload;
      const { shouldReset } = action.meta.arg;
      state.page = shouldReset ? 1 : state.page + 1;
      state.hasMore = data.hasMore;
      state.count = data.count;
      state.players = shouldReset
        ? data.players
        : [...(state.players || []), ...data.players];
    });
    builder.addCase(getPlayers.rejected, (state, action) => {
      console.log(action);
    });
    builder.addCase(getSinglePlayer.fulfilled, (state, action) => {
      state.singlePlayer = action.payload;
    });
  },
});
