import { styled } from "styled-components";
import { SBox, SFlex } from "./styles";
import { devices } from "./responsiveStyles";
import { ghostBlack, mainBgColor } from "./colors";

export const STraitsContainer = styled(SBox)`
  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: 1fr;
  gap: 10px;
  flex: 1;

  @media ${devices.tablet} {
    grid-template-columns: repeat(auto-fit, minmax(400px, 490px));
  }
`;

export const STraitInfo = styled(SFlex)`
  border-radius: 8px;
  background: ${ghostBlack};
  padding: 8px;
`;

export const STraitsFilter = styled(SFlex)`
  position: sticky;
  top: 0;
  z-index: 1;
  background: ${mainBgColor};
  width: 100%;
  padding: 10px;
`;

export const STraitInfoItem = styled(SFlex)`
  flex-direction: column;

  @media ${devices.tablet} {
    flex-direction: row;
  }
`;

export const STraitChemistryImage = styled(SBox)`
  height: 40px;

  @media ${devices.tablet} {
    height: 60px;
  }
`;

export const STraitChemistryItem = styled(SFlex)`
  position: sticky;
  top: 47px; /* height of filter */
  gap: 5px;
  align-items: center;
  padding: 10px 0;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  background: ${mainBgColor};

  @media ${devices.tablet} {
    flex-direction: column;
    width: 120px;
  }
`;
