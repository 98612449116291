import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../AppContext";
import { profileRoute } from "../../constants";
import {
  SInputWrapper,
  SLoginContainer,
  SLoginInner,
  SButton,
  SFlex,
  SInputText,
} from "../../styles";

const Register = () => {
  const navigate = useNavigate();
  const [registration, setRegistration] = useState({
    email: "",
    password: "",
    firstName: "",
    lastName: "",
  });

  const { registerUser } = useAppContext();

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const registerResp = await registerUser(registration);
    if (registerResp?.success === "OK") {
      navigate(profileRoute);
    }
  };

  const handleOnChange = (event: any) => {
    setRegistration({
      ...registration,
      [event.target.name]: event.target.value,
    });
  };

  const { email, password, firstName, lastName } = registration;

  return (
    <SLoginContainer>
      <SLoginInner>
        <h3>Register</h3>
        <form onSubmit={handleSubmit}>
          <SFlex direction="column" gap="10px">
            <SInputWrapper>
              <label>First Name</label>
              <SInputText
                type="text"
                placeholder="First Name"
                name="firstName"
                onChange={handleOnChange}
                value={firstName}
              />
            </SInputWrapper>
            <SInputWrapper>
              <label>Last Name</label>
              <SInputText
                type="text"
                placeholder="Last Name"
                name="lastName"
                onChange={handleOnChange}
                value={lastName}
              />
            </SInputWrapper>
            <SInputWrapper>
              <label>Email</label>
              <SInputText
                type="text"
                placeholder="Email"
                name="email"
                onChange={handleOnChange}
                value={email}
              />
            </SInputWrapper>
            <SInputWrapper>
              <label>Password</label>
              <SInputText
                type="password"
                placeholder="Password"
                name="password"
                onChange={handleOnChange}
                value={password}
              />
            </SInputWrapper>
            <SButton>Register!</SButton>
          </SFlex>
        </form>
      </SLoginInner>
    </SLoginContainer>
  );
};

export default Register;
