import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";
import BaseballLoader from "../BaseballLoader";
import {
  SBodyContainer,
  SBox,
  SFlex,
  STeamInfo,
  STeamLogo,
  SLink,
  STeamInfoHeader,
} from "../../styles";
import Image from "../Image";
import { imageColumns, BLANK_TEAM_IMG, IMAGE_BASE_URL } from "../../constants";
import PageTitle from "../PageTitle";
import { titleCase } from "../../utilities/helpers";
import Checkbox from "../Filters/Checkbox";
import {
  profileRoute,
  sharedTeamsRoute,
  teamsRoute,
  userTeamsRoute,
} from "../../constants";
import { ITeam } from "../../types";
import { getSingleTeam, updateTeamSharing } from "../../state/teamsSlice";
import { useAppDispatch, useAppSelector } from "../../state/store";
import { singleTeamSelector } from "../../state/selectors";
import { deleteTeam } from "../../api/teamsApi";
import HeaderLink from "../HeaderLink";

const TeamInfo = () => {
  const { teamSlug, teamDirectory } = useParams();
  const navigate = useNavigate();
  const [showPitchers, setShowPitchers] = useState(false);

  const dispatch = useAppDispatch();
  const singleTeam = useAppSelector(singleTeamSelector);

  const getTeam = useCallback(async () => {
    dispatch(getSingleTeam({ teamSlug, teamDirectory }));
  }, [teamSlug]);

  useEffect(() => {
    getTeam();
  }, [teamSlug, teamDirectory]);

  const isCustomTeam = useMemo(() => !!singleTeam?.teamDirectory, [singleTeam]);
  const isSharedView = useMemo(
    () => isCustomTeam && !singleTeam.userId,
    [isCustomTeam, singleTeam]
  );
  const isOwnerView = useMemo(
    () => isCustomTeam && singleTeam.userId,
    [isCustomTeam, singleTeam]
  );
  const isShared = useMemo(
    () => isCustomTeam && singleTeam?.isShared,
    [isCustomTeam, singleTeam?.isShared]
  );

  const togglePitchers = () => {
    setShowPitchers(!showPitchers);
  };

  if (!singleTeam) {
    return <BaseballLoader />;
  }

  const customTeamLogo = singleTeam?.teamImages?.team_logo
    ? `${IMAGE_BASE_URL}/${singleTeam.teamImages.team_logo}`
    : BLANK_TEAM_IMG;

  const handleShareTeam = async () => {
    dispatch(
      updateTeamSharing({
        teamDirectory: singleTeam.teamDirectory,
        isShared: !isShared,
      })
    );
  };

  const handleDeleteTeam = async (teamToDelete: ITeam) => {
    if (
      window.confirm(
        `Are you sure you want to delete: ${teamToDelete.name} - ID ${teamToDelete.id}`
      )
    ) {
      await deleteTeam(teamToDelete.id);
      navigate(profileRoute);
    }
  };

  return (
    <STeamInfo>
      <PageTitle title={singleTeam.name} />

      <STeamInfoHeader>
        <STeamLogo gap="10px">
          <Image
            title={singleTeam.name}
            src={
              isCustomTeam
                ? customTeamLogo
                : `${imageColumns.teamSlug}${singleTeam.teamSlug}.png`
            }
            style={{ height: "200px" }}
          />
          <SFlex gap="50px">
            <SFlex direction="column" gap="20px">
              <h1>{singleTeam.name}</h1>
              {isOwnerView && (
                <SFlex gap="20px">
                  <SLink to={`${userTeamsRoute}/${singleTeam.teamSlug}/edit`}>
                    Edit
                  </SLink>
                  <SLink to="" onClick={() => handleDeleteTeam(singleTeam)}>
                    Delete
                  </SLink>
                </SFlex>
              )}
              {singleTeam.league?.name && (
                <SFlex gap="10px" $alignItems="center">
                  <Image
                    title={singleTeam.league.name}
                    src={`${imageColumns.league}${singleTeam.league.name}.png`}
                    style={{ height: "30px" }}
                  />
                  <SBox>
                    <h4>{titleCase(singleTeam.league.name)}</h4>
                  </SBox>
                </SFlex>
              )}
              <SBox>
                <Checkbox
                  checked={showPitchers}
                  onChange={togglePitchers}
                  value={"Show Pitchers"}
                />
              </SBox>
              {isOwnerView && (
                <>
                  <SFlex direction="column" gap="5px">
                    <Checkbox
                      checked={isShared}
                      onChange={handleShareTeam}
                      value={"Share team"}
                    />
                    <p>Makes this team publicly visible to all users</p>
                  </SFlex>
                </>
              )}
            </SFlex>
            <SFlex gap="20px">
              {isCustomTeam && (
                <>
                  <HeaderLink to="overview" text="Players" />
                  <HeaderLink to="stats" text="Stats" />
                </>
              )}
            </SFlex>
          </SFlex>
        </STeamLogo>
        <SLink
          to={
            isSharedView
              ? sharedTeamsRoute
              : isCustomTeam
              ? profileRoute
              : teamsRoute
          }
        >
          Back to{" "}
          {isSharedView ? "Shared Teams" : isCustomTeam ? "Profile" : "Teams"}
        </SLink>
      </STeamInfoHeader>
      <SBodyContainer>
        <Outlet
          context={{
            team: singleTeam,
            showPitchers,
          }}
        />
      </SBodyContainer>
    </STeamInfo>
  );
};

export default TeamInfo;
