import { createSelector } from "@reduxjs/toolkit";
import { TGlobalState } from "../stateTypes";

const playerSelector = ({ players }: TGlobalState) => players.players;
const hasMoreSelector = ({ players }: TGlobalState) => players.hasMore;
const countSelector = ({ players }: TGlobalState) => players.count;
export const playersSelector = createSelector(
  [playerSelector, hasMoreSelector, countSelector],
  (players, hasMorePlayers, playerCount) => ({
    players,
    hasMorePlayers,
    playerCount,
  })
);

export const playerSortSelector = ({ players }: TGlobalState) => players.sort;
export const singlePlayerSelector = ({ players }: TGlobalState) =>
  players.singlePlayer;
