import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  fetchAllSharedTeams,
  fetchAllTeams,
  fetchSingleTeam,
  fetchUpdateSharing,
  fetchUserTeams,
  uploadNewLogo,
  uploadPlayerStats,
} from "../api/teamsApi";
import { TFetchSingleTeamParams, TTeamsState } from "./stateTypes";

const initialState: TTeamsState = {
  loading: false,
  teams: null,
  sharedTeams: null,
  singleTeam: null,
  teamStats: null,
};

export const fetchAllShared = createAsyncThunk("fetchAllShared", async () => {
  const sharedTeams = await fetchAllSharedTeams();
  return sharedTeams;
});

export const setTeamStats = createAsyncThunk(
  "setTeamStats",
  async (params: { formData: any; teamDirectory: string }) => {
    const stats = await uploadPlayerStats(params);
    return stats;
  }
);

export const fetchTeams = createAsyncThunk(
  "fetchTeams",
  async (isCustomTeam?: any) => {
    const fetchedTeams = isCustomTeam
      ? await fetchUserTeams()
      : await fetchAllTeams();

    return fetchedTeams;
  }
);

export const updateTeamSharing = createAsyncThunk(
  "updateTeamSharing",
  async (params: { teamDirectory: string; isShared: boolean }) => {
    const teamData = await fetchUpdateSharing(params);
    return teamData;
  }
);

export const updateTeamLogo = createAsyncThunk(
  "updateTeamLogo",
  async (params: { teamDirectory: string; formData: any }) => {
    const teamData = await uploadNewLogo(params);
    return teamData;
  }
);

export const getSingleTeam = createAsyncThunk(
  "getSingleTeam",
  async ({ teamSlug, teamDirectory, isEdit }: TFetchSingleTeamParams) => {
    const team = await fetchSingleTeam({
      teamSlug,
      teamDirectory,
      isEdit,
    });
    return team;
  }
);

export const teamsSlice = createSlice({
  name: "teamsSlice",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchTeams.fulfilled, (state, action) => {
      state.teams = action.payload;
    });
    builder.addCase(getSingleTeam.fulfilled, (state, action) => {
      state.singleTeam = action.payload;
    });
    builder.addCase(fetchAllShared.fulfilled, (state, action) => {
      state.sharedTeams = action.payload;
    });
    builder.addCase(setTeamStats.fulfilled, (state, action) => {
      state.teamStats = action.payload;
    });
    builder.addCase(updateTeamLogo.fulfilled, (state, action) => {
      state.singleTeam = {
        ...state.singleTeam,
        teamImages: action.payload.teamImages,
      };
    });
    builder.addCase(updateTeamSharing.fulfilled, (state, action) => {
      if (action.payload?.updatedTeam) {
        state.singleTeam = {
          ...state.singleTeam,
          isShared: action.payload?.updatedTeam.isShared,
        };
      }
    });
  },
});
