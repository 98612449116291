import { Helmet } from "react-helmet";
import { CANONICAL_URL } from "../constants";

const PageTitle = ({ title }: { title: string }) => {
  return (
    <Helmet title={title}>
      <link rel="canonical" href={CANONICAL_URL} />
    </Helmet>
  );
};

export default PageTitle;
