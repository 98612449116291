import { useMemo } from "react";
import { SCreateItem, SBox, SButton, SFlex } from "../../../styles";
import {
  ALL_BATS,
  ALL_CHEMISTRY,
  ALL_GENDER,
  ALL_THROWS,
  ALL_TRAITS,
  BLANK_PLAYER_IMG,
  RATING_MAP,
  SKILLS,
  CREATE_ALL_POSITIONS,
} from "../../../constants";
import RadioInput from "./RadioInput";
import { IPlayer } from "../../../types";

import { CREATE_INPUTS } from "../../../utilities/teamCreateHelpers";
import Image from "../../Image";
import CreateInput from "./CreateInput";
import CreateSelect from "./CreateSelect";
import CreateCheckbox from "./CreateCheckbox";

const PlayerCreateItem = ({
  player,
  updatePlayer,
  imgBase,
}: {
  player?: IPlayer;
  updatePlayer: (player: IPlayer, item: any) => void;
  imgBase: string;
}) => {
  const initPlayer = useMemo(() => ({ ...player }), []); // used to reset

  const handleReset = () => {
    updatePlayer(player, { type: "reset", value: initPlayer });
  };

  const handleChange = (event: any) => {
    const value =
      CREATE_INPUTS[event.target.name]?.type === "number"
        ? +event.target.value
        : String(event.target.value);
    updatePlayer(player, { type: event.target.name, value });
  };

  const playerImageSrc =
    player.photoIdx !== null && player.photoIdx > -1
      ? `${imgBase}/player-${player.photoIdx}.png`
      : BLANK_PLAYER_IMG;

  const changeImage = (direction: number = 1) => {
    const idx =
      player.photoIdx !== null && player.photoIdx > -1
        ? player.photoIdx + direction
        : 0;
    const target = {
      name: "photoIdx",
      value: (22 + idx) % 22,
    };
    handleChange({ target });
  };

  return (
    <SCreateItem>
      <SBox
        width={`200px`}
        style={{ position: "relative" }}
        onClick={() => {
          console.log(player);
        }}
      >
        <Image src={playerImageSrc} />
        <SFlex width="100%" justify="space-between">
          <SButton onClick={() => changeImage(-1)}>Prev</SButton>
          <SButton onClick={() => changeImage(1)}>Next</SButton>
        </SFlex>
      </SBox>
      <form id={`player-form-${player.editId}`}>
        <SFlex gap="5px" $alignItems="center">
          {[SKILLS.firstName, SKILLS.lastName].map((x: string) => (
            <CreateInput
              key={`fielding-${x}`}
              disabled={!player.isChecked}
              inputItem={CREATE_INPUTS[x]}
              handleChange={handleChange}
              value={player[CREATE_INPUTS[x].attribute]}
              isText={true}
            />
          ))}
          <SFlex direction="column">
            <p>Throws</p>
            <SFlex>
              {ALL_THROWS.map((t, idx) => (
                <RadioInput
                  key={`throws-${t}`}
                  id={`${player.editId}-${CREATE_INPUTS.throws.attribute}-${idx}`}
                  inputItem={CREATE_INPUTS.throws}
                  isChecked={+player.throws === idx}
                  value={idx}
                  label={t}
                  handleChange={handleChange}
                />
              ))}
            </SFlex>
          </SFlex>
          <SFlex direction="column">
            <p>Bats</p>
            <SFlex>
              {ALL_BATS.map((t, idx) => (
                <RadioInput
                  key={`bat-${t}`}
                  id={`${player.editId}-${CREATE_INPUTS.bats.attribute}-${idx}`}
                  inputItem={CREATE_INPUTS.bats}
                  isChecked={+player.bats === idx}
                  value={idx}
                  label={t}
                  handleChange={handleChange}
                />
              ))}
            </SFlex>
          </SFlex>
          <SFlex direction="column">
            <p>Gender</p>
            <SFlex>
              {ALL_GENDER.map((t, idx) => (
                <RadioInput
                  key={`gender-${t}`}
                  id={`${player.editId}-${CREATE_INPUTS.gender.attribute}-${idx}`}
                  inputItem={CREATE_INPUTS.gender}
                  isChecked={+player.gender === idx}
                  value={idx}
                  label={t}
                  handleChange={handleChange}
                />
              ))}
            </SFlex>
          </SFlex>
        </SFlex>
        <SFlex gap="20px" $alignItems="center">
          <CreateSelect
            inputItem={CREATE_INPUTS.primaryPosition}
            options={CREATE_ALL_POSITIONS.slice(0, 9)}
            handleChange={handleChange}
            value={player[SKILLS.primaryPosition]}
          />
          {player[CREATE_INPUTS.primaryPosition.attribute] !== 0 ? (
            <CreateSelect
              inputItem={CREATE_INPUTS.secondaryPosition}
              options={CREATE_ALL_POSITIONS}
              handleChange={handleChange}
              value={player[SKILLS.secondaryPosition]}
            />
          ) : (
            <CreateSelect
              inputItem={CREATE_INPUTS.pitcherRole}
              options={CREATE_ALL_POSITIONS}
              handleChange={handleChange}
              value={player[SKILLS.pitcherRole]}
            />
          )}
          <CreateSelect
            inputItem={CREATE_INPUTS.ranking}
            options={RATING_MAP}
            handleChange={handleChange}
            value={player[SKILLS.ranking]}
          />
          <CreateSelect
            inputItem={CREATE_INPUTS.playerChemistry}
            options={ALL_CHEMISTRY}
            handleChange={handleChange}
            value={player[SKILLS.playerChemistry]}
          />
          <CreateSelect
            inputItem={CREATE_INPUTS.traitId1}
            options={ALL_TRAITS}
            handleChange={handleChange}
            value={player[SKILLS.traitId1]}
          />
          <CreateSelect
            inputItem={CREATE_INPUTS.traitId2}
            options={ALL_TRAITS}
            handleChange={handleChange}
            value={player[SKILLS.traitId2]}
          />
        </SFlex>
        <SFlex gap="20px" $alignItems="center">
          {[
            SKILLS.power,
            SKILLS.contact,
            SKILLS.speed,
            SKILLS.fielding,
            SKILLS.arm,
            SKILLS.age,
            SKILLS.jerseyNumber,
          ].map((x: string) => (
            <CreateInput
              disabled={!player.isChecked}
              key={`fielding-${x}`}
              inputItem={CREATE_INPUTS[x]}
              handleChange={handleChange}
              value={player[CREATE_INPUTS[x].attribute]}
            />
          ))}
        </SFlex>
        {player[CREATE_INPUTS.primaryPosition.attribute] === 0 && (
          <>
            <SFlex gap="20px" $alignItems="center">
              {[SKILLS.velocity, SKILLS.junk, SKILLS.accuracy].map(
                (x: string) => (
                  <CreateInput
                    disabled={!player.isChecked}
                    key={`fielding-${x}`}
                    inputItem={CREATE_INPUTS[x]}
                    handleChange={handleChange}
                    value={player[CREATE_INPUTS[x].attribute]}
                  />
                )
              )}
            </SFlex>
            <SFlex gap="20px" $alignItems="center">
              <SBox>
                <p>Arsenal</p>
                <SFlex gap="10px" $alignItems="center">
                  {[
                    SKILLS.fourSeamFastball,
                    SKILLS.twoSeamFastball,
                    SKILLS.screwball,
                    SKILLS.changeup,
                    SKILLS.forkball,
                    SKILLS.curveball,
                    SKILLS.slider,
                    SKILLS.cutFastball,
                  ].map((x: string) => (
                    <CreateCheckbox
                      key={`pitch-${x}`}
                      id={`${player.editId}-${CREATE_INPUTS[x].attribute}`}
                      inputItem={CREATE_INPUTS[x]}
                      handleChange={handleChange}
                      isChecked={player[x] === 1}
                    />
                  ))}
                </SFlex>
              </SBox>
            </SFlex>
          </>
        )}
      </form>
      <SFlex direction="column" gap="5px">
        <SButton onClick={handleReset}>Reset Edits</SButton>
        <CreateCheckbox
          id={`${player.editId}-isChecked`}
          inputItem={{ label: "Included", type: "", attribute: "isChecked" }}
          isChecked={player.isChecked}
          handleChange={(event: any) => {
            updatePlayer(player, {
              type: "isChecked",
              value: event.target.value === 1,
            });
          }}
        />
      </SFlex>
    </SCreateItem>
  );
};

export default PlayerCreateItem;
