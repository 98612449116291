import axios from "axios";
import { BASE_URL, FREE_AGENT_TEAM, SESSION_VALUE } from "../constants";
import { ITeam } from "../types";
import { TFetchSingleTeamParams } from "../state/stateTypes";
import { preparePlayerData, prepareStats } from "../utilities/helpers";

const getSession = () => {
  const token = localStorage.getItem(SESSION_VALUE);
  return token ? JSON.parse(token) : {};
};

export const fetchCachedTeam = async (teamDirectory: string) => {
  const tokens = getSession();
  const { data } = await axios.get(`${BASE_URL}/team/cached_team`, {
    headers: { Authorization: `Bearer ${tokens.accessToken}` },
    params: {
      teamDirectory,
    },
  });

  return preparePlayerData(data);
};

export const fetchAllTeams = async () => {
  try {
    const { data } = await axios(`${BASE_URL}/team/all`);
    return data.teams.filter((team: ITeam) => team.name !== FREE_AGENT_TEAM);
  } catch (err) {
    console.log(err, "fetchAllTeams error");
  }
};

export const fetchAllSharedTeams = async () => {
  try {
    const { data } = await axios.get(`${BASE_URL}/team/allShared`);
    return data.sharedTeams;
  } catch (err) {
    console.log(err, "fetchAllSharedTeams error");
  }
};

export const fetchUserTeams = async () => {
  try {
    const tokens = getSession();
    const { data } = await axios(`${BASE_URL}/team/allUser`, {
      headers: { Authorization: `Bearer ${tokens.accessToken}` },
    });
    return data.teams.filter((team: ITeam) => team.name !== FREE_AGENT_TEAM);
  } catch (err) {
    console.log(err, "Failed to fetch user teams");
  }
};

export const fetchSingleTeam = async ({
  teamSlug,
  teamDirectory,
  isEdit,
}: TFetchSingleTeamParams) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/team/single`, {
      params: { teamSlug, teamDirectory, isEdit },
    });
    return data;
  } catch (err) {
    console.log(err, "fetchSingleTeam error");
  }
};

export const createTeam = async (teamData: ITeam) => {
  const tokens = getSession();
  const { data } = await axios.post(
    `${BASE_URL}/team/create`,
    { teamData },
    { headers: { Authorization: `Bearer ${tokens.accessToken}` } }
  );
  console.log(data);
};

export const saveTeamEdit = async (teamData: any) => {
  const tokens = getSession();
  const { data } = await axios.put(
    `${BASE_URL}/team/edit`,
    {
      teamData,
    },
    { headers: { Authorization: `Bearer ${tokens.accessToken}` } }
  );
  console.log(data);
};

export const deleteTeam = async (teamId: number) => {
  const tokens = getSession();
  const { data } = await axios.delete(`${BASE_URL}/team/delete`, {
    data: {
      teamId,
    },
    headers: { Authorization: `Bearer ${tokens.accessToken}` },
  });
  console.log(data);
};

export const fetchUpdateSharing = async ({
  teamDirectory,
  isShared,
}: {
  teamDirectory: string;
  isShared: boolean;
}) => {
  const tokens = getSession();
  const { data } = await axios.put(
    `${BASE_URL}/team/update_sharing`,
    {
      teamDirectory,
      isShared,
    },
    { headers: { Authorization: `Bearer ${tokens.accessToken}` } }
  );

  return data;
};

export const uploadNewLogo = async ({
  formData,
  teamDirectory,
}: {
  formData: any;
  teamDirectory: string;
}) => {
  const tokens = getSession();
  const { data } = await axios({
    method: "POST",
    url: `${BASE_URL}/team/upload_new_logo?teamDirectory=${teamDirectory}`,
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${tokens.accessToken}`,
    },
  });
  return data;
};

export const uploadPlayerStats = async ({
  formData,
  teamDirectory,
}: {
  formData: any;
  teamDirectory: string;
}) => {
  const tokens = getSession();
  const { data } = await axios({
    method: "POST",
    url: `${BASE_URL}/team/add_stats?teamDirectory=${teamDirectory}`,
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${tokens.accessToken}`,
    },
  });

  return prepareStats(data);
};
