import { useSearchParams } from "react-router-dom";

export const useQueryStrings = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const findQueryString = (query: string) => {
    return searchParams.get(query);
  };

  const setQueryString = (query: string, value: string) => {
    setSearchParams((prev) => {
      prev.set(query, value);
      return prev;
    });
  };

  return { setQueryString, findQueryString };
};
