import { useOutlet, useOutletContext } from "react-router-dom";
import { SPlayerTableContainer } from "../../styles";
import PlayersTable from "../PlayersTable";
import { PITCHER_ATTRIBUTES, PLAYER_ATTRIBUTES } from "../../constants";
import { ITeam } from "../../types";

const TeamAttributes = () => {
  const outlet = useOutlet();
  const { team, showPitchers } = useOutletContext<{
    team: ITeam;
    showPitchers?: boolean;
  }>();

  const filteredPlayers = showPitchers
    ? team.players.filter((player) => player.pitcherRole !== undefined)
    : team.players;
  const columns = showPitchers ? PITCHER_ATTRIBUTES : PLAYER_ATTRIBUTES;

  return (
    <>
      {outlet}
      <SPlayerTableContainer
        direction="column"
        width="100%"
        $hasOutlet={!!outlet}
      >
        <PlayersTable
          players={filteredPlayers}
          columns={columns.slice(2)} // no team or league cols
        />
      </SPlayerTableContainer>
    </>
  );
};

export default TeamAttributes;
