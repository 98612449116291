import { SCheckbox } from "../../../styles";
import { TCreateInput } from "../../../types";

const RadioInput = ({
  inputItem,
  isChecked,
  value,
  label,
  handleChange,
  id,
}: {
  inputItem: TCreateInput;
  isChecked: boolean;
  value: string | number;
  label?: string;
  handleChange: (event: any) => void;
  id: string;
}) => {
  return (
    <SCheckbox>
      <input
        id={id}
        checked={isChecked}
        type="radio"
        name={inputItem.attribute}
        value={value}
        onChange={handleChange}
      />
      <label htmlFor={id}>{label || value}</label>
    </SCheckbox>
  );
};

export default RadioInput;
