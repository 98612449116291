import { SBodyContainer, SFlex } from "../../styles";
import {
  STraitChemistryImage,
  STraitChemistryItem,
  STraitInfoItem,
  STraitsContainer,
  STraitsFilter,
} from "../../styles";
import { imageColumns } from "../../constants";
import Image from "../Image";
import { ITrait } from "../../types";
import TraitInfo from "./TraitInfo";
import FilterCheckbox from "../Filters/FilterCheckbox";
import { useTraits } from "../../hooks/useTraits";
import PageTitle from "../PageTitle";
import { changeItem } from "../../utilities/helpers";

const Traits = () => {
  const {
    filterGood,
    setFilterGood,
    filterPitcher,
    setFilterPitcher,
    groupTraits,
  } = useTraits();

  return (
    <SBodyContainer direction="column" mt="10px">
      <PageTitle title="Traits" />
      <STraitsFilter gap="20px">
        <FilterCheckbox
          type="isGood"
          setFilter={(_, value) => {
            setFilterGood(changeItem(filterGood, value));
          }}
          filterItem={filterGood}
        />
        <FilterCheckbox
          type="isPitcher"
          setFilter={(_, value) => {
            setFilterPitcher(changeItem(filterPitcher, value));
          }}
          filterItem={filterPitcher}
        />
      </STraitsFilter>
      <SFlex wrap="wrap" direction="column" width="100%">
        {Object.keys(groupTraits).map((chemistry) => {
          return (
            <STraitInfoItem
              key={chemistry}
              width="100%"
              gap="20px"
              padding="10px"
            >
              <STraitChemistryItem>
                <STraitChemistryImage>
                  <Image
                    title={chemistry}
                    src={`${imageColumns.playerChemistry}${chemistry}.png`}
                    style={{ height: "100%" }}
                  />
                </STraitChemistryImage>
                <h3>{chemistry}</h3>
              </STraitChemistryItem>
              <STraitsContainer>
                {groupTraits[chemistry]
                  ?.sort((a: ITrait, b: ITrait) => (a.type > b.type ? 1 : -1))
                  .map((trait: ITrait) => {
                    return <TraitInfo key={trait.type} trait={trait} />;
                  })}
              </STraitsContainer>
            </STraitInfoItem>
          );
        })}
      </SFlex>
    </SBodyContainer>
  );
};

export default Traits;
