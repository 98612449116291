import { SBox, SImageTitle } from "../styles";
import Image from "./Image";

const ZoomImage = ({ zoomRef, imgUrl, title, style }: any) => {
  return (
    <SBox
      style={{ position: "relative" }}
      onMouseEnter={() => {
        if (zoomRef.current) {
          // load image into zoom box
          zoomRef.current.style.display = "block";
          zoomRef.current.style.backgroundImage = `url('${imgUrl}')`;
        }
      }}
      onMouseMove={(event: any) => {
        if (zoomRef.current) {
          // track mouse movement
          const rect = event.target.getBoundingClientRect();
          const x = 100 * ((event.clientX - rect.left) / rect.width);
          const y = 100 * ((event.clientY - rect.top) / rect.height);
          zoomRef.current.style.backgroundSize =
            event.target.style.naturalWidth;
          zoomRef.current.style.backgroundPosition = `${x}% ${y}%`;
        }
      }}
      onMouseLeave={() => {
        if (zoomRef.current) {
          // close zoom box
          zoomRef.current.style.display = "none";
        }
      }}
    >
      <Image src={imgUrl} style={style} />
      {title && <SImageTitle>{title}</SImageTitle>}
    </SBox>
  );
};

export default ZoomImage;
