import {
  playerColumnSort,
  tableHeaders,
  centeredColumns,
  numberColumns,
  NO_SORT_COLUMNS,
  SKILLS,
} from "../constants";
import { IPlayer, IFieldingStats, IPitchingStats } from "../types";
import { STable, SCol, SHead, SSortArrow, SSortCell } from "../styles";
import TableCell from "./TableCell";
import BaseballLoader from "./BaseballLoader";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { useAppSelector } from "../state/store";
import { playerSortSelector } from "../state/selectors";

const PlayersTable = ({
  players,
  loadMoreRef,
  sort,
  hasMore,
  columns,
}: {
  players?: (IFieldingStats | IPitchingStats | IPlayer)[];
  loadMoreRef?: (node?: Element | null) => void;
  sort?: (sortAttr: string) => void;
  hasMore?: boolean;
  columns: string[];
}) => {
  const [sortAttr, setSortAttr] = useState(
    !!sort
      ? undefined
      : {
          key: SKILLS.fullName,
          isAsc: true,
        }
  );
  const { playerSlug } = useParams();
  const playerSort = useAppSelector(playerSortSelector);
  if (!players) {
    return <BaseballLoader />;
  }

  const sortedPlayers = !!sort
    ? players
    : [...players].sort((a, b) => {
        if (sortAttr.isAsc) {
          return a[sortAttr.key] > b[sortAttr.key] ? 1 : -1;
        }
        return a[sortAttr.key] > b[sortAttr.key] ? -1 : 1;
      });

  const localSort = (key: string) => {
    const isAsc = key === sortAttr?.key ? !sortAttr.isAsc : false;
    setSortAttr({ key, isAsc });
  };

  return (
    <>
      <STable>
        <thead>
          <tr>
            {columns.map((attributeKey) => {
              const isCentered = centeredColumns.includes(attributeKey);
              const isSort = [
                attributeKey,
                playerColumnSort[attributeKey],
              ].includes(sortAttr?.key || playerSort.sortAttr);
              return (
                <SHead
                  key={`header-${attributeKey}`}
                  $isSort={isSort}
                  onClick={() => {
                    if (NO_SORT_COLUMNS.includes(attributeKey)) {
                      return;
                    }
                    const key = playerColumnSort[attributeKey] ?? attributeKey;
                    if (!!sort) {
                      sort(key);
                    } else {
                      localSort(key);
                    }
                  }}
                >
                  <SSortCell
                    gap="3px"
                    $alignItems="center"
                    justify={isCentered ? "center" : "flex-start"}
                  >
                    {tableHeaders[attributeKey] ?? attributeKey}
                    {isSort && (
                      <SSortArrow>
                        {(
                          sortAttr?.isAsc !== undefined
                            ? sortAttr.isAsc
                            : playerSort.isAsc
                        )
                          ? "\u25B2"
                          : "\u25BC"}
                      </SSortArrow>
                    )}
                  </SSortCell>
                </SHead>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {sortedPlayers.length > 0 ? (
            sortedPlayers?.map((player, idx) => {
              const isSelected =
                !!playerSlug && player.playerSlug === playerSlug;
              return (
                <tr key={`${idx}-${player.fullName}`}>
                  {columns.map((attributeKey) => {
                    const isCentered = centeredColumns.includes(attributeKey);
                    const isNumber = numberColumns.includes(attributeKey);
                    return (
                      <SCol
                        key={`column-${attributeKey}`}
                        $isSelected={isSelected}
                        $isCentered={isCentered}
                        $isNumber={isNumber}
                      >
                        <TableCell attribute={attributeKey} player={player} />
                      </SCol>
                    );
                  })}
                </tr>
              );
            })
          ) : (
            <tr>
              <SCol colSpan={columns.length} style={{ textAlign: "center" }}>
                No Players found
              </SCol>
            </tr>
          )}
        </tbody>
      </STable>
      {hasMore && loadMoreRef && (
        <div ref={loadMoreRef}>
          <p>{`Loading more players`}</p>
        </div>
      )}
    </>
  );
};

export default PlayersTable;
