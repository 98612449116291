import { useEffect } from "react";
import { SBox, SFlex, SLink } from "../../styles";
import { BLANK_TEAM_IMG, IMAGE_BASE_URL } from "../../constants";
import { sharedTeamsRoute } from "../../constants";
import Image from "../Image";
import { fetchAllShared } from "../../state/teamsSlice";
import { useAppDispatch, useAppSelector } from "../../state/store";
import { sharedTeamsSelector } from "../../state/selectors";

const SharedTeams = () => {
  const dispatch = useAppDispatch();
  const sharedTeams = useAppSelector(sharedTeamsSelector);

  useEffect(() => {
    dispatch(fetchAllShared());
  }, []);

  return (
    <div>
      <h1>Shared Teams</h1>
      <SBox>
        <SFlex wrap="wrap" gap="25px">
          {sharedTeams?.map((team) => (
            <SLink
              to={`${sharedTeamsRoute}/${team.teamDirectory}`}
              key={team.id}
            >
              <SFlex $alignItems="center" direction="column">
                <Image
                  src={
                    team.teamImages?.team_logo
                      ? `${IMAGE_BASE_URL}/${team.teamImages?.team_logo}`
                      : BLANK_TEAM_IMG
                  }
                  style={{ width: "100px" }}
                />
                <h3>{team.name || `ID ${team.id}`}</h3>
              </SFlex>
            </SLink>
          ))}
        </SFlex>
      </SBox>
    </div>
  );
};

export default SharedTeams;
