import { imageColumns } from "../../constants";
import { SFlex } from "../../styles";
import { IPlayer } from "../../types";
import Image from "../Image";

const PlayerTraits = ({ player }: { player: IPlayer }) => {
  return (
    <SFlex direction="column" style={{ paddingLeft: "40px", width: "100%" }}>
      {[player.trait1, player.trait2].map((trait) => {
        if (!trait?.type) {
          return null;
        }
        const { type, chemistry } = trait;
        return (
          <SFlex $alignItems="center" gap="4px" key={trait.type}>
            <Image
              title={chemistry}
              src={`${imageColumns.trait}${chemistry.toLowerCase()}.png`}
              style={{ height: "30px", width: "20px" }}
            />
            <div>{type}</div>
          </SFlex>
        );
      })}
    </SFlex>
  );
};

export default PlayerTraits;
