import { useParams } from "react-router-dom";
import { useCallback, useEffect } from "react";
import Image from "../Image";
import {
  PLAYER_ATTR,
  PITCHER_ATTR,
  imageColumns,
  FREE_AGENT_TEAM,
  BLANK_PLAYER_IMG,
  IMAGE_BASE_URL,
} from "../../constants";
import { SFlex, SLink } from "../../styles";
import {
  SAbilities,
  SAttrContainer,
  SImageContainer,
  SInfo,
  SPlayerInfoContainer,
  STraits,
  SCloseButton,
} from "../../styles";
import BaseballLoader from "../BaseballLoader";
import Arsenal from "./Arsenal";
import Ability from "./Ability";
import PlayerChemistry from "./PlayerChemistry";
import PlayerTraits from "./PlayerTraits";
import PageTitle from "../PageTitle";
import { getSinglePlayer } from "../../state/playersSlice";
import { useAppDispatch, useAppSelector } from "../../state/store";
import { singlePlayerSelector } from "../../state/selectors";

const PlayerInfo = () => {
  const { teamSlug, playerSlug, teamDirectory } = useParams();
  const dispatch = useAppDispatch();
  const player = useAppSelector(singlePlayerSelector);
  const hasPitcherRole = !!player?.pitcherRole;

  const getPlayer = useCallback(async () => {
    dispatch(
      getSinglePlayer({
        playerSlug,
        teamSlug,
        teamDirectory,
      })
    );
  }, [playerSlug]);

  useEffect(() => {
    getPlayer();
  }, [getPlayer]);

  if (!player) {
    return <BaseballLoader />;
  }

  const playerImgSrc =
    player.photoIdx !== null
      ? `${IMAGE_BASE_URL}/custom_teams/${player.teamDirectory}/player-${player.photoIdx}.png`
      : `${imageColumns.playerImage}${player.localID}.png`;

  return (
    <SPlayerInfoContainer>
      <PageTitle title={player.fullName} />
      <SLink to={".."}>
        <SCloseButton>&#x2715;</SCloseButton>
      </SLink>
      <SImageContainer>
        {player.team !== FREE_AGENT_TEAM ? (
          <Image
            alt={player.fullName}
            src={playerImgSrc}
            style={{ height: "100%" }}
          />
        ) : (
          <>
            <Image
              alt={player.fullName}
              src={BLANK_PLAYER_IMG}
              style={{ height: "100%" }}
            />
            <p style={{ marginBottom: "5px" }}>{player.fullName}</p>
          </>
        )}
      </SImageContainer>
      <SAttrContainer>
        <SInfo>
          <p>AGE</p>
          <p className="attrValue">{player.age}</p>
          <p>BAT</p>
          <p className="attrValue">{player.bats}</p>
          <p>THR</p>
          <p className="attrValue">{player.throws}</p>
        </SInfo>
        {hasPitcherRole && <Arsenal arsenal={player.arsenal} />}
        <SAbilities justify={hasPitcherRole ? "space-between" : "center"}>
          <SFlex direction="column" gap="8px" $alignItems="center">
            {PLAYER_ATTR.map((attr) => {
              if (!player[attr]) {
                return null;
              }
              return <Ability player={player} attribute={attr} key={attr} />;
            })}
          </SFlex>
          {hasPitcherRole && (
            <SFlex direction="column" gap="8px" $alignItems="center">
              {PITCHER_ATTR.map((attr) => (
                <Ability player={player} attribute={attr} key={attr} />
              ))}
            </SFlex>
          )}
        </SAbilities>
        <STraits direction="column">
          {player.playerChemistry && <PlayerChemistry player={player} />}
          <PlayerTraits player={player} />
        </STraits>
      </SAttrContainer>
    </SPlayerInfoContainer>
  );
};

export default PlayerInfo;
