import { imageColumns } from "../../constants";
import { STraitInfo, pitcherTag, SBox, SFlex, STag } from "../../styles";
import { ITrait } from "../../types";
import Image from "../Image";

const TraitInfo = ({ trait }: { trait: ITrait }) => {
  return (
    <STraitInfo gap="4px">
      <SFlex
        width="50px"
        gap="5px"
        direction="column"
        pt="6px"
        $alignItems="center"
      >
        <Image
          title={trait.chemistry as string}
          src={`${imageColumns.trait}${trait.chemistry.toLowerCase()}.png`}
          style={{ width: "30px" }}
        />
        <STag $bgColor={trait.isGood ? "green" : "maroon"}>
          {trait.isGood ? "Good" : "Bad"}
        </STag>
        {trait.isPitcher && <STag $bgColor={pitcherTag}>Pitcher</STag>}
      </SFlex>
      <SBox style={{ flex: 1 }}>
        <SFlex gap="4px" $alignItems="center" mb="6px">
          <h3>{trait.type}</h3>
        </SFlex>
        <ul style={{ flex: 1 }}>
          {[trait.level1, trait.level2, trait.level3].map((lvl, idx) => (
            <li style={{ marginBottom: "5px" }} key={`${lvl}-${idx}`}>
              {lvl}
            </li>
          ))}
        </ul>
      </SBox>
    </STraitInfo>
  );
};

export default TraitInfo;
