import { Link, NavLink } from "react-router-dom";
import styled from "styled-components";
import { checkedLabel, linkHoverColor, pitcherTag } from "./colors";
import { devices } from "./responsiveStyles";

const Spacing = {
  0: "2px",
  1: "4px",
  2: "8px",
  3: "12px",
  4: "16px",
};

export const SImage = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

export const SBox = styled.div<{
  width?: string;
  height?: string;
  margin?: string;
  padding?: string;
  mt?: string;
  mb?: string;
  mr?: string;
  ml?: string;
  pt?: string;
  pb?: string;
  pr?: string;
  pl?: string;
  background?: string;
  flex?: number;
  overflow?: string;
}>`
  width: ${({ width }) => width ?? undefined};
  height: ${({ height }) => height ?? undefined};
  margin: ${({ margin }) => margin ?? undefined};
  padding: ${({ padding }) => padding ?? undefined};
  margin-top: ${({ mt }) => mt ?? undefined};
  margin-bottom: ${({ mb }) => mb ?? undefined};
  margin-right: ${({ mr }) => mr ?? undefined};
  margin-left: ${({ ml }) => ml ?? undefined};
  padding-top: ${({ pt }) => pt ?? undefined};
  padding-bottom: ${({ pb }) => pb ?? undefined};
  padding-right: ${({ pr }) => pr ?? undefined};
  padding-left: ${({ pl }) => pl ?? undefined};
  background: ${({ background }) => background ?? undefined};
  flex: ${({ flex }) => flex ?? undefined};
  overflow: ${({ overflow }) => overflow ?? undefined};
`;

export const SFlex = styled(SBox)<{
  $alignItems?: string;
  direction?: string;
  justify?: string;
  gap?: string;
  wrap?: string;
}>`
  display: flex;
  flex-direction: ${({ direction }) => direction ?? "row"};
  align-items: ${({ $alignItems }) => $alignItems ?? "flex-start"};
  justify-content: ${({ justify }) => justify ?? "flex-start"};
  gap: ${({ gap }) => gap ?? "0"};
  flex-wrap: ${({ wrap }) => wrap ?? "nowrap"};
`;

export const STag = styled(SBox)<{
  $bgColor?: string;
  $color?: string;
}>`
  display: inline-block;
  border-radius: 8px;
  padding: 2px 5px;
  font-size: 12px;
  background: ${({ $bgColor }) => $bgColor || "gray"};
  color: ${({ $color }) => $color || "white"};
`;

export const SLink = styled(Link)`
  color: white;

  &:hover {
    color: ${linkHoverColor};
  }
`;

export const SNavLink = styled(NavLink)`
  &.active {
    background: linear-gradient(
      0deg,
      rgba(28, 35, 106, 1) 0%,
      rgba(126, 70, 151, 1) 33%,
      rgba(168, 39, 42, 1) 64%,
      rgba(106, 20, 14, 1) 100%
    );
  }
`;

export const SHeader = styled.h1`
  color: red;
`;

export const SAppContainer = styled(SFlex)`
  color: white;
  overflow: auto;
  width: 100%;
  /* https://chanind.github.io/javascript/2019/09/28/avoid-100vh-on-mobile-web.html */
  height: ${window.innerHeight}px;

  @media ${devices.tablet} {
    height: 100vh;
    padding: ${Spacing[3]};
  }
`;

export const SBodyContainer = styled(SFlex)`
  width: 100%;
  flex: 1;
  overflow: auto;

  @media ${devices.tablet} {
    gap: 12px;
  }
`;

export const SInputText = styled.input`
  padding: 8px 10px;
  border-radius: 8px;
  font-size: 18px;
  border: none;
`;

export const SCheckbox = styled.div`
  text-align: center;
  label {
    color: white;
    display: block;
    padding: 4px 6px;
    background: gray;
    min-width: 32px;
    cursor: pointer;
    box-shadow: inset 0 0 0px 1px white;
  }

  input[type="checkbox"] {
    display: none;
  }

  input[type="checkbox"]:checked + label {
    background: ${checkedLabel};
  }

  input[type="radio"] {
    display: none;
  }

  input[type="radio"]:checked + label {
    background: ${checkedLabel};
  }
`;

export const SFilterPositionTitle = styled.h4<{ width?: string }>`
  width: ${({ width }) => width ?? "90px"};
  text-align: right;
`;

export const SPitchCell = styled.div`
  color: white;
  background: black;
  padding: 1px 2px;
  border: 1px solid white;
`;

export const SFilterAllNone = styled(SFlex)`
  font-size: 14px;
  width: 100%;

  span {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const SButton = styled.button`
  padding: 4px 8px;
  border: none;
  border-radius: 8px;
  background: ${checkedLabel};
  color: white;
  cursor: pointer;
  transition: 0.25s linear background;
  &:hover {
    background: ${pitcherTag};
  }
`;

export const SLinkButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  color: white;
  text-decoration: underline;
  cursor: pointer;
`;

export const SErrorLabel = styled.p`
  color: red;
  font-weight: bold;
  font-size: 20px;
`;
