import { useRef } from "react";
import { EXAMPLE_IMG_TITLES, IMAGE_BASE_URL } from "../../../constants";
import { SBox, SFlex, SZoomViewer } from "../../../styles";
import ZoomImage from "../../ZoomImage";

const EXAMPLE_IMAGES = [
  { title: EXAMPLE_IMG_TITLES[0], imgSrc: "examples/stats_1.jpg" },
  { title: EXAMPLE_IMG_TITLES[1], imgSrc: "examples/stats_2.jpg" },
  { title: EXAMPLE_IMG_TITLES[2], imgSrc: "examples/stats_3.jpg" },
  { title: EXAMPLE_IMG_TITLES[3], imgSrc: "examples/stats_4.jpg" },
  { title: EXAMPLE_IMG_TITLES[4], imgSrc: "examples/team_grid_example.jpg" },
  { title: EXAMPLE_IMG_TITLES[5], imgSrc: "examples/team_logo_example.png" },
];

const CreateInstructions = () => {
  const zoomRef = useRef();
  return (
    <SBox padding="10px" flex={1}>
      <SFlex direction="column" gap="10px">
        <h3>Use these 6 images to auto-input your new team.</h3>
        <SFlex gap="20px" wrap="wrap">
          {EXAMPLE_IMAGES.map(({ title, imgSrc }) => (
            <SBox>
              <h3>{title}</h3>
              <ZoomImage
                key={imgSrc}
                zoomRef={zoomRef}
                imgUrl={`${IMAGE_BASE_URL}/${imgSrc}`}
                style={{ width: "350px" }}
              />
            </SBox>
          ))}
        </SFlex>
      </SFlex>
      <SZoomViewer
        ref={zoomRef}
        style={{ right: "10px", bottom: "10px", width: "50%", height: "300px" }}
      />
    </SBox>
  );
};

export default CreateInstructions;
