import styled from "styled-components";
import { SBox, SFlex, STag } from "./styles";
import { ghostWhite, ghostBlack, skillGradient } from "./colors";

export const SInputWrapper = styled(SFlex)`
  justify-content: space-between;
  label {
    width: 100px;
  }
`;

export const SSharedTag = styled(STag)`
  position: absolute;
  top: 6px;
  right: 6px;
  background: green;
`;

export const SImageTitle = styled.h3`
  position: absolute;
  bottom: 5px;
  right: 5px;
  color: white;
  padding: 0px 6px;
  background: ${ghostBlack};
  border-radius: 4px;
`;

export const STeamUploadPreview = styled(SFlex)`
  position: sticky;
  top: 0;
  gap: 5px;
  margin-top: 20px;
  flex-direction: column;
  width: 350px;

  .img-component {
    border: 1px solid white;
  }
`;

export const SProfileContainer = styled(SBox)`
  display: grid;
  grid-template-columns: 400px 1fr;
  grid-template-rows: repeat(4, 1fr);
  grid-gap: 10px;
  width: 100%;
  flex: 1;
  margin-top: 4px;
`;

const SProfileGridItem = styled(SBox)`
  background: ${ghostWhite};
  border-radius: 4px;
  padding: 8px;
`;

export const SCreateItem = styled(SProfileGridItem)`
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
  width: 100%;
`;

export const SProfileSidebar = styled(SProfileGridItem)`
  grid-column: 1;
  grid-row: 1 / 5;
`;

export const SProfileTeams = styled(SProfileGridItem)`
  grid-column: 2;
  grid-row: 1 / 5;
`;

export const SProfileLeagues = styled(SProfileGridItem)`
  grid-column: 2;
  grid-row: 3 / 5;
`;

export const SDragZone = styled(SBox)`
  text-align: center;
  width: 100%;
  border: 4px dashed black;
  padding: 80px;

  &.inside-drag-area {
    border-color: red;
  }

  input[type="file"] {
    display: none;
  }
`;

export const SZoomViewer = styled.div`
  position: absolute;
  z-index: 10;
  display: none;
  height: 200px;
  width: 60%;
  border-radius: 10px;
  border: 3px solid white;
  background-color: lightblue;
  background-repeat: no-repeat;
  background-size: 140%;
`;

export const SLoginContainer = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const SLoginInner = styled.div`
  background: ${skillGradient[14]};
  padding: 40px;
  border-radius: 20px;
`;
