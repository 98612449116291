import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ITeam } from "../../../types";
import TeamEditForm from "./TeamEditForm";
import PageTitle from "../../PageTitle";
import BaseballLoader from "../../BaseballLoader";
import { fetchSingleTeam, saveTeamEdit } from "../../../api/teamsApi";

const TeamEdit = () => {
  const { teamSlug } = useParams();
  const [teamEdit, setTeamEdit] = useState<ITeam | undefined>();

  const getTeam = async () => {
    const singleTeam = await fetchSingleTeam({ teamSlug, isEdit: true });
    setTeamEdit(singleTeam);
  };

  useEffect(() => {
    getTeam();
  }, [teamSlug]);

  const save = async (updatedTeam: ITeam) => {
    await saveTeamEdit(updatedTeam);
  };

  if (!teamEdit) {
    return <BaseballLoader />;
  }

  return teamEdit?.teamDirectory ? (
    <>
      <PageTitle title={`Edit "${teamEdit.name}"`} />
      <h1>Edit Team</h1>
      <TeamEditForm team={teamEdit} save={save} />
    </>
  ) : (
    <div>No team found</div>
  );
};

export default TeamEdit;
