import { PITCH_TYPE } from "../../constants";
import { SArsenal, SFlex, SPitchCell } from "../../styles";
import { IPlayer } from "../../types";

const Arsenal = ({ arsenal }: { arsenal?: IPlayer["arsenal"] }) => {
  return (
    <SArsenal gap="8px" $alignItems="center" justify="center">
      <SFlex gap="5px" justify="center">
        {arsenal?.map((pitch: string) => (
          <SPitchCell key={pitch}>{PITCH_TYPE[pitch].short}</SPitchCell>
        ))}
      </SFlex>
    </SArsenal>
  );
};

export default Arsenal;
