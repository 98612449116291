import { useEffect } from "react";
import { useAppContext } from "../../AppContext";
import { userTeamCreateRoute, userTeamsRoute } from "../../constants";
import {
  SProfileContainer,
  SProfileSidebar,
  SProfileTeams,
  SSharedTag,
} from "../../styles";
import { SBox, SButton, SFlex, SLink } from "../../styles";
import PageTitle from "../PageTitle";
import Image from "../Image";
import { BLANK_TEAM_IMG, IMAGE_BASE_URL } from "../../constants";
import { fetchTeams } from "../../state/teamsSlice";
import { teamsSelector } from "../../state/selectors";
import { useAppDispatch, useAppSelector } from "../../state/store";

const Profile = () => {
  const { isLoggedIn, logoutUser } = useAppContext();
  const dispatch = useAppDispatch();
  const teams = useAppSelector(teamsSelector);

  const getTeams = () => {
    dispatch(fetchTeams(true));
  };

  useEffect(() => {
    getTeams();
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      getTeams();
    }
  }, [isLoggedIn]);

  if (!isLoggedIn) {
    return <div>NOT LOGGED IN</div>;
  }

  return (
    <SProfileContainer>
      <PageTitle title="Profile" />
      <SProfileSidebar>
        <SFlex direction="column" justify="space-between" height="100%">
          <SFlex justify="space-between" width="100%">
            <h1>Welcome!</h1>
            <SButton onClick={logoutUser}>Logout</SButton>
          </SFlex>
        </SFlex>
      </SProfileSidebar>
      <SProfileTeams>
        <SFlex gap="10px" $alignItems="center" mb="10px">
          <h1>My Teams</h1>
          <SBox>
            <SLink to={userTeamCreateRoute}>Create Team</SLink>
          </SBox>
        </SFlex>
        <SBox>
          <SFlex wrap="wrap" gap="25px">
            {teams?.map((team) => (
              <SLink to={`${userTeamsRoute}/${team.teamSlug}`} key={team.id}>
                <SFlex $alignItems="center" direction="column">
                  <SBox style={{ position: "relative" }}>
                    <Image
                      src={
                        team.teamImages?.team_logo
                          ? `${IMAGE_BASE_URL}/${team.teamImages?.team_logo}`
                          : BLANK_TEAM_IMG
                      }
                      style={{ width: "100px" }}
                    />
                    {team.isShared && <SSharedTag>Shared</SSharedTag>}
                  </SBox>
                  <h3>{team.name || `ID ${team.id}`}</h3>
                </SFlex>
              </SLink>
            ))}
          </SFlex>
        </SBox>
      </SProfileTeams>
    </SProfileContainer>
  );
};

export default Profile;
