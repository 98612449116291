import styled, { keyframes } from "styled-components";

const rightBottomLeftTop = keyframes`
  0% {
    background-position: 0% 50%;
    background-size: 150%;
  }
  
  90%, 100% {
    background-position: 100% 30%;
    background-size: 130%;
  }
`;

const leftTopRightBottom = keyframes`
  0% {
    background-position: 30% 30%;
    background-size: 200%;
  }

  90%, 100% {
    background-position: 60%;
    background-size: 130%;
  }
`;

const slideDown = keyframes`
  0% {
    background-position: center 20%;
  }

  90%, 100% {
    background-position: center 60%;
  }
`;
export const STitle = styled.h1`
  font-weight: 800;
  font-size: 200px;
  text-shadow: -1px -1px 0 #f00, 1px -1px 0 #f00, -1px 1px 0 #f00,
    1px 1px 0 #f00;
  color: black;
  opacity: 0.75;
`;

export const SSubTitle = styled(STitle)`
  font-size: 100px;
`;

const SHomeHeroContainer = styled.div<{ src: string }>`
  display: flex;
  align-items: center;
  padding: 0 20px;
  margin-top: 20px;
  background-image: ${({ src }) => `url(${src})`};
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 250px;
  border-radius: 5px;
`;

const DURATION = "15s";
export const SPitchHit = styled(SHomeHeroContainer)`
  animation: ${leftTopRightBottom} ${DURATION} ease-in-out alternate infinite;
`;

export const SFeelCrowd = styled(SHomeHeroContainer)`
  animation: ${slideDown} ${DURATION} ease-in-out alternate infinite;
`;

export const SOutfield = styled(SHomeHeroContainer)`
  animation: ${rightBottomLeftTop} ${DURATION} ease-in-out alternate infinite;
`;

export const SWalking = styled(SHomeHeroContainer)`
  animation: ${slideDown} ${DURATION} ease-in-out alternate infinite;
`;
