import { SBox, SFlex } from "../../../styles";
import { TCreateInput } from "../../../types";
import SkillCell from "../../SkillCell";

const CreateInput = ({
  inputItem,
  handleChange,
  value,
  isText,
  disabled,
}: {
  inputItem: TCreateInput;
  handleChange: (event: any) => void;
  value: any;
  isText?: boolean;
  disabled?: boolean;
}) => {
  return (
    <SFlex direction="column" style={{ minWidth: "100px" }}>
      <label>{inputItem.label}</label>
      <SBox
        flex={1}
        style={{ width: "100%", height: "50px", position: "relative" }}
      >
        {!isText && (
          <SBox
            style={{
              height: "100%",
              width: "100%",
              position: "absolute",
              top: 0,
              zIndex: -1,
            }}
          >
            <SkillCell
              value={value}
              hideValue={true}
              style={{ height: "100%", width: "100%" }}
            />
          </SBox>
        )}
        <input
          min={1}
          max={99}
          type={inputItem.type}
          name={inputItem.attribute}
          value={value}
          disabled={disabled}
          onChange={handleChange}
          style={{
            width: "100%",
            background: isText ? "black" : "none",
            color: "white",
            fontSize: "22px",
            fontWeight: "bold",
            border: "none",
            textAlign: isText ? "unset" : "center",
          }}
        />
      </SBox>
    </SFlex>
  );
};
export default CreateInput;
