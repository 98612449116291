import { createSlice } from "@reduxjs/toolkit";
import { TFilterState } from "./stateTypes";
import {
  ALL_GENDER,
  ALL_BATS,
  ALL_THROWS,
  ALL_LEAGUE,
  ALL_POSITIONS,
  ALL_PITCHING,
  SECOND_POSITIONS,
  ALL_PITCHES,
  ALL_TRAITS,
  FILTER_RATING_MAP,
  ALL_CHEMISTRY,
  ALL_TEAMS,
} from "../constants";
import { TFilter } from "../types";
import { changeItem } from "../utilities/helpers";

const initialState: TFilterState = {
  nameFilter: "",
  gender: ALL_GENDER.map((name) => ({ name, checked: false })),
  bats: ALL_BATS.map((name) => ({ name, checked: false })),
  throws: ALL_THROWS.map((name) => ({ name, checked: false })),
  league: ALL_LEAGUE.map((name) => ({ name, checked: false })),
  position: ALL_POSITIONS.map((name) => ({ name, checked: false })),
  pitching: ALL_PITCHING.map((name) => ({ name, checked: false })),
  secondPosition: SECOND_POSITIONS.map((name) => ({ name, checked: false })),
  pitches: ALL_PITCHES.map((name) => ({ name, checked: false })),
  traits: ALL_TRAITS.map((name) => ({ name, checked: false })),
  traits2: ALL_TRAITS.map((name) => ({ name, checked: false })),
  ranking: FILTER_RATING_MAP.map((name) => ({ name, checked: false })),
  playerChemistry: ALL_CHEMISTRY.map((name) => ({
    name,
    checked: false,
  })),
  teams: ALL_TEAMS.map((name) => ({ name, checked: false })),
};

const filtersSlice = createSlice({
  name: "filtersSlice",
  initialState,
  reducers: {
    setAllFilters: (state, action) => {
      const { type, isOn, nameQuery } = action.payload as {
        type: string;
        isOn: boolean;
        nameQuery?: string;
      };
      if (type === "position") {
        const values = state.position.map((item) => ({
          ...item,
          checked: isOn,
        }));
        state.position = values;
      }
      if (type === "pitching") {
        const values = state.pitching.map((item) => ({
          ...item,
          checked: isOn,
        }));
        state.pitching = values;
      }
      if (type === "secondPosition") {
        const values = state.secondPosition.map((item) => ({
          ...item,
          checked: isOn,
        }));
        state.secondPosition = values;
      }
      if (type === "pitches") {
        const values = state.pitches.map((item) => ({
          ...item,
          checked: isOn,
        }));
        state.pitches = values;
      }
      if (type === "playerChemistry") {
        const values = state.playerChemistry.map((item) => ({
          ...item,
          checked: isOn,
        }));
        state.playerChemistry = values;
      }
      if (type === "traits") {
        const values = state.traits.map((item) => ({ ...item, checked: isOn }));
        state.traits = values;
      }
      if (type === "traits2") {
        const values = state.traits2.map((item) => ({
          ...item,
          checked: isOn,
        }));
        state.traits2 = values;
      }
      if (type === "teams") {
        const values = state.teams.map((item) => ({ ...item, checked: isOn }));
        state.teams = values;
      }
      if (type === "ranking") {
        const values = state.ranking.map((item) => ({
          ...item,
          checked: isOn,
        }));
        state.ranking = values;
      }
      if (nameQuery !== undefined) {
        state.nameFilter = nameQuery;
      }
    },
    setFilter: (state, action) => {
      const { type, value, nameQuery } = action.payload as {
        type: string;
        value: TFilter;
        nameQuery?: string;
      };
      if (type === "gender") {
        state.gender = changeItem(state.gender, value);
      }
      if (type === "bats") {
        state.bats = changeItem(state.bats, value);
      }
      if (type === "throws") {
        state.throws = changeItem(state.throws, value);
      }
      if (type === "league") {
        state.league = changeItem(state.league, value);
      }
      if (type === "position") {
        state.position = changeItem(state.position, value);
      }
      if (type === "pitching") {
        state.pitching = changeItem(state.pitching, value);
      }
      if (type === "secondPosition") {
        state.secondPosition = changeItem(state.secondPosition, value);
      }
      if (type === "pitches") {
        state.pitches = changeItem(state.pitches, value);
      }
      if (type === "traits") {
        state.traits = changeItem(state.traits, value);
      }
      if (type === "traits2") {
        state.traits2 = changeItem(state.traits2, value);
      }
      if (type === "playerChemistry") {
        state.playerChemistry = changeItem(state.playerChemistry, value);
      }
      if (type === "teams") {
        state.teams = changeItem(state.teams, value);
      }
      if (type === "ranking") {
        state.ranking = changeItem(state.ranking, value);
      }
      if (nameQuery !== undefined) {
        state.nameFilter = nameQuery;
      }
    },
  },
});

export const { setAllFilters, setFilter } = filtersSlice.actions;

export default filtersSlice;
