import styled from "styled-components";
import { SBox, SFlex } from "./styles";
import { devices } from "./responsiveStyles";
import { checkedLabel } from "./colors";

export const SPlayerInfoContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;

  @media ${devices.tablet} {
    width: 300px;
  }
`;

export const SCloseButton = styled(SBox)`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${checkedLabel};
  top: 4px;
  left: 4px;
  border: 2px solid white;
  border-radius: 50%;
  height: 30px;
  width: 30px;
`;

export const SImageContainer = styled(SBox)`
  @media ${devices.tablet} {
    width: 300px;
  }
`;

export const SAttrContainer = styled.div`
  background: black;
`;

export const SInfo = styled(SFlex)`
  background: #090d17;
  margin-bottom: 20px;
  p {
    flex: 1;
    padding: 8px;
    text-align: center;
  }
  .attrValue {
    background: #101623;
  }
`;

export const SArsenal = styled(SFlex)`
  padding: 0 20px;
  margin-bottom: 20px;
`;

export const SAbilities = styled(SFlex)`
  padding: 0 20px 20px;
`;

export const STraits = styled(SAbilities)`
  padding: 0 20px 20px;
`;
