import {
  PITCH_TYPE,
  SKILLS,
  RATING_MAP,
  imageColumns,
  FREE_AGENT_TEAM,
} from "../constants";
import SkillCell from "./SkillCell";
import { SFullNameCell, STeamCell, SFlex, SLink, SPitchCell } from "../styles";
import Image from "./Image";
import { IFieldingStats, IPitchingStats, IPlayer } from "../types";

type TProps = {
  attribute: string;
  player: IFieldingStats | IPitchingStats | IPlayer;
};

const TableCell = ({ attribute, player }: TProps) => {
  const value = player[attribute];
  if ([undefined, null].includes(value)) {
    return value;
  }

  switch (attribute) {
    case SKILLS.fullName:
      return (
        <SFullNameCell $alignItems="center">
          {player.teamSlug && (
            <Image
              title={value}
              src={`${imageColumns[SKILLS.teamSlug]}${player.teamSlug}.png`}
              style={{ width: "30px" }}
            />
          )}
          <SLink to={`player/${player.playerSlug}`}>{value}</SLink>
        </SFullNameCell>
      );
    case SKILLS.arsenal:
      return (
        <SFlex gap="5px" justify="center">
          {value.map((pitch: string) => (
            <SPitchCell key={pitch}>{PITCH_TYPE[pitch].short}</SPitchCell>
          ))}
        </SFlex>
      );
    case SKILLS.team:
      if (value === FREE_AGENT_TEAM) {
        return undefined;
      }
      return (
        <STeamCell $alignItems="center" gap="4px">
          <Image
            title={value}
            src={`${imageColumns[SKILLS.teamSlug]}${player.teamSlug}.png`}
            style={{ width: "30px" }}
          />
          <div>{value}</div>
        </STeamCell>
      );
    case SKILLS.power:
    case SKILLS.contact:
    case SKILLS.speed:
    case SKILLS.fielding:
    case SKILLS.arm:
    case SKILLS.velocity:
    case SKILLS.junk:
    case SKILLS.accuracy:
      return <SkillCell value={+value} />;
    case SKILLS.trait2:
    case SKILLS.trait1:
      return value?.chemistry && value?.type ? (
        <SFlex $alignItems="center" gap="4px" style={{ width: "150px" }}>
          <Image
            title={value.chemistry as string}
            src={`${
              imageColumns[attribute]
            }${value.chemistry.toLowerCase()}.png`}
            style={{ height: "30px", width: "20px" }}
          />
          <div>{value.type}</div>
        </SFlex>
      ) : (
        value.type
      );
    case SKILLS.playerChemistry:
      return (
        <SFlex $alignItems="center" gap="4px">
          <Image
            title={value as string}
            src={`${imageColumns[attribute]}${value as string}.png`}
            style={{ height: "30px", width: "30px" }}
          />
          <div>{value}</div>
        </SFlex>
      );
    case SKILLS.league:
      return (
        <Image
          title={value as string}
          src={`${imageColumns[attribute]}${value as string}.png`}
          style={{ height: "30px" }}
        />
      );
    case SKILLS.ranking:
      return RATING_MAP[+value];
    default:
      return <div>{value}</div>;
  }
};

export default TableCell;
