import { useRef } from "react";
import useLoading from "../../hooks/useLoading";
import { SFlex, SLinkButton } from "../../styles";
import BaseballLoader from "../BaseballLoader";
import { ITeam } from "../../types";
import { useAppDispatch } from "../../state/store";
import { setTeamStats } from "../../state/teamsSlice";

type TProps = {
  team: ITeam;
};

const TeamStatsUploader = ({ team }: TProps) => {
  const dispatch = useAppDispatch();
  const { loading, setLoading } = useLoading();
  const uploadRef = useRef<HTMLInputElement>();

  const submitImage = async (files) => {
    setLoading(true);
    const formData = new FormData();

    if (!files) {
      return;
    }

    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
    }

    await dispatch(
      setTeamStats({ formData, teamDirectory: team.teamDirectory })
    );
    setLoading(false);
  };

  return (
    <SFlex $alignItems="end">
      {loading && <BaseballLoader />}
      <input
        type="file"
        name="files"
        multiple
        ref={uploadRef}
        style={{ display: "none" }}
        onChange={(event: any) => {
          submitImage(event.target.files);
        }}
      />
      <SLinkButton
        type="button"
        disabled={loading}
        onClick={() => {
          uploadRef?.current.click();
        }}
      >
        {loading ? "Please wait..." : "Upload Stats"}
      </SLinkButton>
    </SFlex>
  );
};

export default TeamStatsUploader;
