import { styled } from "styled-components";
import { SBox, SFlex } from "./styles";
import { devices } from "./responsiveStyles";
import { ghostBlack, ghostWhite, mainBgColor } from "./colors";

export const STeamView = styled(SBox)`
  width: 100%;
  flex: 1;
  position: sticky;
  top: 0;
  background: ${mainBgColor};
  width: 100%;

  @media ${devices.tablet} {
    flex: unset;
  }
`;

export const STeamInfo = styled(SFlex)`
  height: 100%;
  width: 100%;
  overflow: auto;
  flex-direction: column;
`;

export const STeamInfoHeader = styled(SFlex)`
  width: 100%;
  height: 200px;
  justify-content: space-between;
  align-items: flex-end;
  margin: 20px 0;
  padding: 0 50px 0 0;
`;

export const STeamLogo = styled(SFlex)`
  .team-logo {
    display: none;
  }

  @media ${devices.tablet} {
    .team-logo {
      display: unset;
    }
  }
`;

export const SLeagueContainer = styled(SFlex)`
  width: 100%;
  gap: 10px;
  align-items: stretch;
`;

export const SLeague = styled(SBox)`
  text-align: center;
  padding: 10px;
  border-radius: 5px;
  background: ${ghostWhite};
`;

export const STeamGrid = styled(SBox)`
  width: 100%;
  flex: 1;
  padding: 10px;
  border-radius: 5px;
  background: ${ghostWhite};
  display: grid;
  grid-template-columns: repeat(auto-fit, 200px);
  gap: 5px;

  @media ${devices.tablet} {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }
`;

export const STeamPlayerGrid = styled(SBox)`
  display: grid;
  grid-auto-rows: 300px;
  gap: 5px;
  width: 100%;
`;

export const SImageBox = styled(SBox)`
  height: 100%;
  width: 100px;

  @media ${devices.tablet} {
    width: 300px;
  }
`;

export const STeamPlayerItem = styled(SFlex)`
  background: ${ghostBlack};
  border-radius: 8px;
  padding: 5px;

  @media ${devices.tablet} {
    height: 100%;
    flex-direction: row;
  }
`;

export const SPlayerBio = styled(SBox)`
  h1 {
    font-size: 24px;
  }
  h2 {
    font-size: 18px;
    font-weigt: normal;
    white-space: pre-line;
  }
`;
