import { useEffect, useMemo, useState } from "react";
import axios from "axios";
import { BASE_URL } from "../constants";
import { ITrait } from "../types";

export const useTraits = () => {
  const [allTraits, setAllTraits] = useState<ITrait[]>();

  const fetchAllTraits = async () => {
    const { data } = await axios(`${BASE_URL}/traits/all`);
    setAllTraits(data.traits);
  };

  const [filterGood, setFilterGood] = useState([
    { name: "Good", checked: true },
    { name: "Bad", checked: true },
  ]);
  const [filterPitcher, setFilterPitcher] = useState([
    { name: "Pitcher", checked: true },
    { name: "Player", checked: true },
  ]);

  useEffect(() => {
    fetchAllTraits();
  }, []);

  const groupTraits = useMemo(() => {
    const [{ checked: isGood }, { checked: isBad }] = filterGood;
    const [{ checked: isPitcher }, { checked: isPlayer }] = filterPitcher;

    return (
      allTraits?.reduce((acc, trait) => {
        acc[trait.chemistry] ||= [];

        const activeGood = trait.isGood && isGood;
        const activeBad = !trait.isGood && isBad;
        const activePitcher = trait.isPitcher && isPitcher;
        const activePlayer = !trait.isPitcher && isPlayer;

        if ((activeGood || activeBad) && (activePitcher || activePlayer)) {
          acc[trait.chemistry].push(trait);
        }
        return acc;
      }, {} as any) || {}
    );
  }, [allTraits, filterGood, filterPitcher]);

  return {
    filterGood,
    setFilterGood,
    filterPitcher,
    setFilterPitcher,
    groupTraits,
  };
};
