import { useCallback, useEffect, useMemo } from "react";
import { useOutlet } from "react-router-dom";

import PlayersTable from "../PlayersTable";
import { useInView } from "react-intersection-observer";
import { useAppContext } from "../../AppContext";
import Filters from "../Filters";
import { SPlayerTableContainer, SBodyContainer } from "../../styles";
import { PITCHER_ATTRIBUTES, PLAYER_ATTRIBUTES } from "../../constants";
import PageTitle from "../PageTitle";
import { useAppDispatch, useAppSelector } from "../../state/store";
import { getPlayers, playersSlice } from "../../state/playersSlice";
import { playersSelector, filtersSelector } from "../../state/selectors";

const Players = () => {
  const dispatch = useAppDispatch();
  const outlet = useOutlet();
  const { isPitchers } = useAppContext();
  const { filters, nameFilter } = useAppSelector(filtersSelector);
  const { players, hasMorePlayers, playerCount } =
    useAppSelector(playersSelector);

  const apiFilters = useMemo(() => {
    return Object.keys(filters).reduce((acc: any, filterKey) => {
      acc[filterKey] = filters[filterKey]
        .filter((f) => f.checked)
        .map((f) => f.name);
      return acc;
    }, {});
  }, [filters]);

  const dispatchSortPlayers = (sortAttr: string) => {
    dispatch(playersSlice.actions.sortPlayers({ sortAttr }));
    dispatchPlayers(true);
  };

  const dispatchPlayers = useCallback(
    (shouldReset: boolean = false) => {
      dispatch(getPlayers({ isPitchers, shouldReset, apiFilters, nameFilter }));
    },
    [JSON.stringify(apiFilters)]
  );

  useEffect(() => {
    dispatchPlayers(true);
  }, [isPitchers, dispatchPlayers]);

  const { ref } = useInView({
    threshold: 0.25,
    onChange: (inView) => {
      if (inView && !!players && hasMorePlayers) {
        dispatchPlayers();
      }
    },
  });

  const columns = isPitchers ? PITCHER_ATTRIBUTES : PLAYER_ATTRIBUTES;

  return (
    <>
      <PageTitle title={isPitchers ? `Pitchers` : `Players`} />
      <Filters count={playerCount} isPitcher={isPitchers} />
      <SBodyContainer>
        {outlet}
        <SPlayerTableContainer direction="column" $hasOutlet={!!outlet}>
          <PlayersTable
            players={players}
            loadMoreRef={ref}
            hasMore={hasMorePlayers}
            sort={dispatchSortPlayers}
            columns={columns}
          />
        </SPlayerTableContainer>
      </SBodyContainer>
    </>
  );
};

export default Players;
