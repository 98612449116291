import { useEffect, useState } from "react";
import TeamUploader from "./TeamUploader";
import { useQueryStrings } from "../../../hooks/useQueryStrings";
import { ITeam } from "../../../types";
import TeamEditForm from "./TeamEditForm";
import useUploadImages from "../../../hooks/useUploadImages";
import PageTitle from "../../PageTitle";
import { SFlex } from "../../../styles";
import CreateInstructions from "./CreateInstructions";
import BaseballLoader from "../../BaseballLoader";
import { createTeam, fetchCachedTeam } from "../../../api/teamsApi";

const TeamCreate = () => {
  const { uploadTeamAttributes } = useUploadImages();
  const { setQueryString, findQueryString } = useQueryStrings();
  const [team, setTeam] = useState<ITeam | undefined>();
  const teamDirectory = findQueryString("teamCode");

  const fetchCachedTeamData = async () => {
    if (teamDirectory) {
      const data = await fetchCachedTeam(teamDirectory);
      setTeam({
        name: "",
        teamDirectory,
        players: data.playerData,
        teamImages: data.teamImages,
      });
    }
  };

  useEffect(() => {
    fetchCachedTeamData();
  }, []);

  useEffect(() => {
    if (team?.teamDirectory) {
      setQueryString("teamCode", team.teamDirectory);
    }
  }, [team?.players]);

  const save = async (updatedTeam: ITeam) => {
    if (updatedTeam?.teamDirectory) {
      await createTeam(updatedTeam);
    }
  };

  if (!team && teamDirectory) {
    return <BaseballLoader />;
  }

  return (
    <>
      <PageTitle title="Create Team" />
      <h1>Create Team</h1>
      {team?.teamDirectory ? (
        <TeamEditForm team={team} save={save} />
      ) : (
        <SFlex>
          <CreateInstructions />
          <TeamUploader
            teamDirectory={teamDirectory}
            label="Attributes upload"
            upload={uploadTeamAttributes}
            setTeam={setTeam}
          />
        </SFlex>
      )}
    </>
  );
};

export default TeamCreate;
