import { SKILLS } from "../constants";
import { TCreateInput } from "../types";

export const CREATE_INPUTS: {
  [key: string]: TCreateInput;
} = {
  [SKILLS.firstName]: {
    label: "First Name",
    attribute: SKILLS.firstName,
    type: "text",
  },
  [SKILLS.lastName]: {
    label: "Last Name",
    attribute: SKILLS.lastName,
    type: "text",
  },
  [SKILLS.throws]: {
    label: "Throws",
    attribute: SKILLS.throws,
    type: "number",
  },
  [SKILLS.bats]: { label: "Bats", attribute: SKILLS.bats, type: "number" },
  [SKILLS.jerseyNumber]: {
    label: "Jersey",
    attribute: SKILLS.jerseyNumber,
    type: "number",
  },
  [SKILLS.gender]: {
    label: "Gender",
    attribute: SKILLS.gender,
    type: "number",
  },
  [SKILLS.primaryPosition]: {
    label: "Position",
    attribute: SKILLS.primaryPosition,
    type: "number",
  },
  [SKILLS.pitcherRole]: {
    label: "Pitching Position",
    attribute: SKILLS.pitcherRole,
    type: "number",
  },
  [SKILLS.secondaryPosition]: {
    label: "2nd Position",
    attribute: SKILLS.secondaryPosition,
    type: "number",
  },
  [SKILLS.age]: { label: "Age", attribute: SKILLS.age, type: "number" },
  [SKILLS.ranking]: {
    label: "Rating",
    attribute: SKILLS.ranking,
    type: "number",
  },

  [SKILLS.salary]: {
    label: "salary",
    attribute: SKILLS.salary,
    type: "number",
  },
  [SKILLS.accuracy]: {
    label: "accuracy",
    attribute: SKILLS.accuracy,
    type: "number",
  },
  [SKILLS.arm]: { label: "arm", attribute: SKILLS.arm, type: "number" },
  [SKILLS.junk]: { label: "junk", attribute: SKILLS.junk, type: "number" },
  [SKILLS.velocity]: {
    label: "velocity",
    attribute: SKILLS.velocity,
    type: "number",
  },
  [SKILLS.fourSeamFastball]: {
    label: "4F",
    attribute: SKILLS.fourSeamFastball,
    type: "number",
  },
  [SKILLS.twoSeamFastball]: {
    label: "2F",
    attribute: SKILLS.twoSeamFastball,
    type: "number",
  },
  [SKILLS.screwball]: {
    label: "SB",
    attribute: SKILLS.screwball,
    type: "number",
  },
  [SKILLS.changeup]: {
    label: "CH",
    attribute: SKILLS.changeup,
    type: "number",
  },
  [SKILLS.forkball]: {
    label: "FK",
    attribute: SKILLS.forkball,
    type: "number",
  },
  [SKILLS.curveball]: {
    label: "CB",
    attribute: SKILLS.curveball,
    type: "number",
  },
  [SKILLS.slider]: { label: "SL", attribute: SKILLS.slider, type: "number" },
  [SKILLS.cutFastball]: {
    label: "CF",
    attribute: SKILLS.cutFastball,
    type: "number",
  },
  [SKILLS.power]: { label: "power", attribute: SKILLS.power, type: "number" },
  [SKILLS.contact]: {
    label: "contact",
    attribute: SKILLS.contact,
    type: "number",
  },
  [SKILLS.speed]: { label: "speed", attribute: SKILLS.speed, type: "number" },
  [SKILLS.fielding]: {
    label: "fielding",
    attribute: SKILLS.fielding,
    type: "number",
  },

  [SKILLS.playerChemistry]: {
    label: "chemistry",
    attribute: SKILLS.playerChemistry,
    type: "text",
  },
  [SKILLS.traitId1]: {
    label: "traitId1",
    attribute: SKILLS.traitId1,
    type: "text",
  },
  [SKILLS.traitId2]: {
    label: "traitId2",
    attribute: SKILLS.traitId2,
    type: "text",
  },
  [SKILLS.photoIdx]: {
    label: "photoIdx",
    attribute: SKILLS.photoIdx,
    type: "number",
  },
};

export const initPlayerState = {
  firstName: "first",
  lastName: "last",
  primaryPosition: "primaryPosition",
  secondaryPosition: "secondaryPosition",
  pitcherRole: "pitcherRole",
  throws: 0,
  bats: 0,
  gender: 0,
  age: 25,
  jerseyNumber: 0,
  isChecked: true,
};

export const getCoords = (lines: any) => {
  if (!lines) {
    return lines;
  }

  return lines.map((line: any) => {
    const top = line.polygon[1];
    const left = line.polygon[0];
    const width = line.polygon[2] - line.polygon[0];
    const height = line.polygon[5] - line.polygon[1];
    return {
      ...line,
      coords: {
        top,
        left,
        width,
        height,
      },
    };
  });
};
