import { useEffect } from "react";
import {
  STeamGrid,
  STeamView,
  SBodyContainer,
  SNavLink,
  SLeagueContainer,
  SLeague,
  SFlex,
} from "../../styles";
import { SKILLS, imageColumns } from "../../constants";
import Image from "../Image";
import PageTitle from "../PageTitle";
import BaseballLoader from "../BaseballLoader";
import { ITeam } from "../../types";
import { titleCase } from "../../utilities/helpers";
import { teamsSelector } from "../../state/selectors";
import { fetchTeams } from "../../state/teamsSlice";
import { useAppDispatch, useAppSelector } from "../../state/store";

const Teams = () => {
  const dispatch = useAppDispatch();
  const teams = useAppSelector(teamsSelector);

  useEffect(() => {
    dispatch(fetchTeams(false));
  }, []);

  if (!teams) {
    return <BaseballLoader />;
  }

  const teamsByLeague: { [league: string]: ITeam[] } = teams.reduce(
    (acc, team) => {
      if (!team.league?.name) {
        return acc;
      }
      if (!acc[team.league.name]) {
        acc[team.league.name] = [];
      }
      acc[team.league.name].push(team);
      return acc;
    },
    {}
  );

  return (
    <SBodyContainer direction="column" mt="10px">
      <PageTitle title="Teams" />
      <STeamView>
        <SFlex direction="column" gap="20px">
          {Object.entries(teamsByLeague).map(([league, leagueTeams]) => {
            return (
              <SLeagueContainer key={league}>
                <SLeague>
                  <Image
                    title={league}
                    src={`${imageColumns[SKILLS.league]}${league}.png`}
                    style={{ width: "100px" }}
                  />
                  {titleCase(league)}
                </SLeague>
                <STeamGrid>
                  {leagueTeams.map((team) => {
                    if (!team.name) {
                      return null;
                    }
                    return (
                      <SNavLink key={team.name} to={`${team.teamSlug}`}>
                        <Image
                          title={team.name}
                          src={`${imageColumns[SKILLS.teamSlug]}${
                            team.teamSlug
                          }.png`}
                          style={{ width: "100%" }}
                        />
                      </SNavLink>
                    );
                  })}
                </STeamGrid>
              </SLeagueContainer>
            );
          })}
        </SFlex>
      </STeamView>
    </SBodyContainer>
  );
};

export default Teams;
