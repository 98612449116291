import { setAllFilters } from "../../state/filtersSlice";
import { useAppDispatch } from "../../state/store";
import { SFilterAllNone } from "../../styles";

const FilterAllNone = ({ type }: { type: string }) => {
  const dispatch = useAppDispatch();
  return (
    <SFilterAllNone gap="5px">
      <span
        onClick={() => {
          dispatch(setAllFilters({ type, isOn: true }));
        }}
      >
        All
      </span>
      <span
        onClick={() => {
          dispatch(setAllFilters({ type, isOn: false }));
        }}
      >
        None
      </span>
    </SFilterAllNone>
  );
};

export default FilterAllNone;
