import { SCheckbox } from "../../../styles";

const CreateCheckbox = ({
  inputItem,
  handleChange,
  isChecked,
  id,
}: {
  inputItem: { label: string; type: string; attribute: string };
  handleChange: any;
  id: any;
  isChecked: boolean;
}) => {
  return (
    <SCheckbox>
      <input
        checked={isChecked}
        onChange={(event) => {
          const value = event.target.checked ? 1 : 0;
          handleChange({ target: { name: event.target.name, value } });
        }}
        type="checkbox"
        name={inputItem.attribute}
        id={id}
      />
      <label htmlFor={id}>{inputItem.label}</label>
    </SCheckbox>
  );
};

export default CreateCheckbox;
