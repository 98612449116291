import { IMAGE_BASE_URL } from "../constants";
import { SBox, SPitchHit, SFeelCrowd, SOutfield, SWalking } from "../styles";

const Home = () => {
  return (
    <SBox width="100%">
      <SBox width="70%">
        <SPitchHit src={`${IMAGE_BASE_URL}/home/hitting_pitch.jpeg`} />
      </SBox>
      <SBox width="70%" ml="auto">
        <SFeelCrowd src={`${IMAGE_BASE_URL}/home/feel_the_crowd.jpeg`} />
      </SBox>
      <SBox width="70%">
        <SOutfield src={`${IMAGE_BASE_URL}/home/redrock_outfield.jpeg`} />
      </SBox>
      <SBox width="70%" ml="auto">
        <SWalking src={`${IMAGE_BASE_URL}/home/walking_back.jpeg`} />
      </SBox>
    </SBox>
  );
};

export default Home;
