import { useReducer, useRef } from "react";
import { SDragZone } from "../../../styles";
type TProps = {
  label: string;
  setFiles: (event: any) => void;
};

const SET_DROP_DEPTH = "SET_DROP_DEPTH";
const SET_IN_DROP_ZONE = "SET_IN_DROP_ZONE";
const ADD_FILE_TO_LIST = "ADD_FILE_TO_LIST";

const reducer = (state, action) => {
  switch (action.type) {
    case SET_DROP_DEPTH:
      return { ...state, dropDepth: action.dropDepth };
    case SET_IN_DROP_ZONE:
      return { ...state, inDropZone: action.inDropZone };
    case ADD_FILE_TO_LIST:
      return { ...state, fileList: state.fileList.concat(action.files) };
    default:
      return state;
  }
};

// https://www.smashingmagazine.com/2020/02/html-drag-drop-api-react/
const DropZoneUploader = ({ label, setFiles }: TProps) => {
  const dropRef = useRef<HTMLInputElement>();
  const [data, dispatch] = useReducer(reducer, {
    dropDepth: 0,
    inDropZone: false,
    fileList: [],
  });

  const handleDragEnter = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch({ type: SET_DROP_DEPTH, dropDepth: data.dropDepth + 1 });
  };
  const handleDragLeave = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch({ type: SET_DROP_DEPTH, dropDepth: data.dropDepth - 1 });
    if (data.dropDepth > 0) return;
    dispatch({ type: SET_IN_DROP_ZONE, inDropZone: false });
  };
  const handleDragOver = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch({ type: SET_IN_DROP_ZONE, inDropZone: true });
  };
  const handleDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    let files = [...e.dataTransfer.files];

    if (files && files.length > 0) {
      const existingFiles = data.fileList.map((f) => f.name);
      files = files.filter((f) => !existingFiles.includes(f.name));

      setFiles(files);
      e.dataTransfer.clearData();
      dispatch({ type: SET_DROP_DEPTH, dropDepth: 0 });
      dispatch({ type: SET_IN_DROP_ZONE, inDropZone: false });
    }
  };

  return (
    <SDragZone
      className={data.inDropZone ? "inside-drag-area" : ""}
      onDrop={(e) => handleDrop(e)}
      onDragOver={(e) => handleDragOver(e)}
      onDragEnter={(e) => handleDragEnter(e)}
      onDragLeave={(e) => handleDragLeave(e)}
      onClick={() => dropRef.current.click()}
    >
      <input
        ref={dropRef}
        type="file"
        name="files"
        multiple
        onChange={(e: any) => {
          setFiles(e.target.files);
        }}
      />
      <h3>{label}</h3>
      <p>
        <strong>Drag</strong> images here or <strong>Click</strong>
      </p>
    </SDragZone>
  );
};

export default DropZoneUploader;
