import { useOutletContext } from "react-router-dom";
import TeamStatsUploader from "./TeamStatsUploader";
import { ITeam } from "../../types";
import { useAppSelector } from "../../state/store";
import { statsSelector } from "../../state/selectors";
import PlayersTable from "../PlayersTable";
import { FIELDING_STATS, PITCHING_STATS } from "../../constants";
import { useMemo } from "react";
import { SBox, SFlex } from "../../styles";

const TeamStats = () => {
  const { team, showPitchers } = useOutletContext<{
    team: ITeam;
    showPitchers?: boolean;
  }>();
  const teamStats = useAppSelector(statsSelector);

  const playerStat = useMemo(() => {
    if (!teamStats) {
      return [];
    }
    const values = Object.values(teamStats);
    return values
      .map(({ fielding, pitching }) => {
        const output = showPitchers ? pitching : fielding;
        return Object.values(output).length === 0 ? null : output;
      })
      .filter((x) => x);
  }, [teamStats, showPitchers]);

  return (
    <SBox width="100%">
      {teamStats ? (
        <SFlex width="100%">
          <PlayersTable
            players={playerStat}
            columns={showPitchers ? PITCHING_STATS : FIELDING_STATS}
          />
        </SFlex>
      ) : (
        <TeamStatsUploader team={team} />
      )}
    </SBox>
  );
};

export default TeamStats;
