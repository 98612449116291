import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { profileRoute } from "../../constants";
import { useAppContext } from "../../AppContext";
import {
  SInputWrapper,
  SLoginContainer,
  SLoginInner,
  SButton,
  SFlex,
  SInputText,
} from "../../styles";

const Login = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const { loginUser } = useAppContext();
  const navigate = useNavigate();
  const handleSubmit = async (email: string, password: string) => {
    const loginResp = await loginUser({ email, password });

    if (loginResp?.success === "OK") {
      navigate(profileRoute);
    }
  };

  return (
    <SLoginContainer>
      <SLoginInner>
        <h3>Login</h3>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            handleSubmit(email, password);
          }}
        >
          <SFlex direction="column" gap="10px">
            <SInputWrapper>
              <label>Email</label>
              <SInputText
                type="text"
                placeholder="Email"
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
                value={email}
              />
            </SInputWrapper>
            <SInputWrapper>
              <label>Password</label>
              <SInputText
                type="password"
                placeholder="Password"
                onChange={(event) => {
                  setPassword(event.target.value);
                }}
                value={password}
              />
            </SInputWrapper>
            <SButton>Login!</SButton>
          </SFlex>
        </form>
      </SLoginInner>
    </SLoginContainer>
  );
};

export default Login;
