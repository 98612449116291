import { useNavigate } from "react-router-dom";
import {
  EXAMPLE_IMG_TITLES,
  IMAGE_BASE_URL,
  MAX_PLAYERS,
} from "../../../constants";
import {
  STeamUploadPreview,
  SZoomViewer,
  SBox,
  SButton,
  SErrorLabel,
  SFlex,
  SInputText,
} from "../../../styles";
import { IPlayer, ITeam } from "../../../types";
import PlayerCreateItem from "./PlayerCreateItem";
import { profileRoute } from "../../../constants";
import { useMemo, useRef, useState } from "react";
import { randomizer } from "../../../utilities/helpers";
import TeamLogoUploader from "./TeamLogoUploader";
import ZoomImage from "../../ZoomImage";

type TProps = {
  team: ITeam;
  save: (team: ITeam) => Promise<void>;
};

const TEAM_IMAGES_NAMES = [
  "attributes_1",
  "attributes_2",
  "attributes_3",
  "attributes_4",
];

const TeamEditForm = ({ team, save }: TProps) => {
  const zoomRef = useRef<HTMLDivElement>();
  const navigate = useNavigate();
  const [teamName, setTeamName] = useState(team.name || "");
  const [players, setPlayers] = useState(
    team.players
      .map((p) => ({ ...p, isChecked: true, editId: randomizer(5000) }))
      .sort((a, b) => (+a.jerseyNumber > +b.jerseyNumber ? 1 : -1))
  );

  const updatePlayer = (player: IPlayer, payload) => {
    const updatedPlayers = players.map((p) => {
      let playerCopy = { ...p };
      if (player.editId === p.editId) {
        if (payload.type === "reset") {
          playerCopy = payload.value;
        } else {
          playerCopy[payload.type] = payload.value;
        }
      }
      return playerCopy;
    });
    setPlayers(updatedPlayers);
  };

  const playerCount = useMemo(() => {
    return players.filter(({ isChecked }) => isChecked).length;
  }, [players]);

  return (
    <>
      {playerCount > MAX_PLAYERS && (
        <SErrorLabel>
          Too many players! Maximum {MAX_PLAYERS} allowed!
        </SErrorLabel>
      )}
      <SFlex gap="40px" width="100%">
        <SBox flex={1}>
          <form>
            <SFlex justify="space-between" padding="10px 0">
              <SFlex gap="15px" $alignItems="center">
                <SInputText
                  placeholder="Team Name"
                  required
                  type="text"
                  value={teamName}
                  onChange={(event) => setTeamName(event.target.value)}
                />
                <SButton
                  type="button"
                  onClick={async () => {
                    const updatedPlayers = players.filter(
                      ({ isChecked }) => isChecked
                    );
                    if (teamName && updatedPlayers.length === MAX_PLAYERS) {
                      await save({
                        ...team,
                        name: teamName,
                        players: updatedPlayers,
                      });
                      navigate(profileRoute);
                    }
                  }}
                >
                  Save
                </SButton>
              </SFlex>
              <TeamLogoUploader team={team} />
            </SFlex>
          </form>
          {players.map((player) => {
            return (
              <PlayerCreateItem
                key={`player-${player.editId}`}
                player={player}
                updatePlayer={updatePlayer}
                imgBase={`${IMAGE_BASE_URL}/custom_teams/${team.teamDirectory}`}
              />
            );
          })}
        </SBox>
        <STeamUploadPreview>
          <h3>Hover to verify correct values</h3>
          {TEAM_IMAGES_NAMES.map((key, idx) => {
            const imgSrc = team.teamImages[key];
            const imgUrl = `${IMAGE_BASE_URL}/${imgSrc}`;

            return (
              <ZoomImage
                key={imgSrc}
                zoomRef={zoomRef}
                imgUrl={imgUrl}
                title={EXAMPLE_IMG_TITLES[idx]}
              />
            );
          })}
        </STeamUploadPreview>
        <SZoomViewer ref={zoomRef} style={{ top: "10px", left: "10px" }} />
      </SFlex>
    </>
  );
};

export default TeamEditForm;
