import {
  ALL_BATS,
  ALL_THROWS,
  ALL_TRAITS,
  CHEMISTRY_MAP,
  RATING_MAP,
  CREATE_ALL_POSITIONS,
} from "../constants";
import { TFilter } from "../types";

export const randomizer = (max: number = 1, min: number = 0) => {
  return Math.round(Math.random() * (max - min) + min);
};

export const wait = async (delay = 1000) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve("");
    }, delay);
  });
};

export const loadImageDataAsync = async (file: Blob): Promise<string> =>
  new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      if (e.target) {
        resolve(e.target.result as string);
      }
    };
    reader.readAsDataURL(file);
  });

export const loadImageAsync = async (imgSrc: string): Promise<string> =>
  new Promise((resolve) => {
    const img = new Image();
    img.onload = () => {
      resolve("");
    };
    img.src = imgSrc;
  });

export const numArray = (count: number) => {
  return Array(count)
    .fill("")
    .map((_, i) => i);
};
export const titleCase = (text?: string) => {
  return text ? text[0].toUpperCase() + text.slice(1).toLowerCase() : "";
};

const removeNonDigit = (word?: string) => {
  const cleanedDigit = word?.replace(/[\D]/gi, "");
  return cleanedDigit ? Math.min(+cleanedDigit, 99) : 0;
};
const removeNonChar = (word?: string) => {
  return word?.replace(/[\W]/gi, "") || "";
};

const extractAttributesInfo = (data: any) => {
  const allAttrs = data.attributes.map((e: any) => {
    const fields = e.analyzeResult.documents[0].fields;
    return fields[Object.keys(fields)[0]].valueArray.map(
      ({ valueObject }: any) =>
        Object.entries(valueObject).reduce((item, [key, value]: any[]) => {
          item[key] = value.content;
          return item;
        }, {} as any)
    );
  });

  const players = allAttrs.reduce((total: any, attrSet: any) => {
    attrSet.forEach((player: any) => {
      const key = player["NAME"];
      if (!total[key]) {
        total[key] = {};
      }
      total[key] = { ...total[key], ...player };
    });
    return total;
  }, {});

  const playerData = Object.values(players).map((field: any) => {
    const nameSplit = field.NAME?.split(" ");
    const firstName = nameSplit?.[0];
    const lastName = nameSplit?.slice(1).join(" ");
    const arsenalSplit = field["ARSENAL"] ? field["ARSENAL"].split(" ") : [];

    const player = {
      fullName: field.NAME,
      firstName: titleCase(firstName?.trim()),
      lastName: titleCase(lastName?.trim()),
      primaryPosition: field["P. POS"]?.trim().replace(/[.,]/gi, ""),
      secondaryPosition: field["S. POS"]?.trim(),
      ranking: field["OVR"]?.trim(),
      salary: field["SALARY"]?.trim().replace(/[$m]/gi, "") * 10e5,

      bats: field["BAT"]?.trim(),
      throws: field["THR"]?.trim(),
      age: removeNonDigit(field["AGE"]),

      fourSeamFastball: arsenalSplit.includes("4F") ? 1 : 0,
      twoSeamFastball: arsenalSplit.includes("2F") ? 1 : 0,
      screwball: arsenalSplit.includes("SB") ? 1 : 0,
      changeup: arsenalSplit.includes("CH") ? 1 : 0,
      forkball: arsenalSplit.includes("FK") ? 1 : 0,
      curveball: arsenalSplit.includes("CB") ? 1 : 0,
      slider: arsenalSplit.includes("SL") ? 1 : 0,
      cutFastball: arsenalSplit.includes("CF") ? 1 : 0,

      accuracy: field["ACC"] ? removeNonDigit(field["ACC"]) : 1,
      arm: field["ARM"] ? removeNonDigit(field["ARM"]) : 1,
      junk: field["JNK"] ? removeNonDigit(field["JNK"]) : 1,
      velocity: field["VEL"] ? removeNonDigit(field["VEL"]) : 1,

      contact: removeNonDigit(field["CON"]) ?? 1,
      fielding: removeNonDigit(field["FLD"]) ?? 1,
      power: removeNonDigit(field["POW"]) ?? 1,
      speed: removeNonDigit(field["SPD"]) ?? 1,

      playerChemistry: removeNonChar(field["CHEM"])?.trim(),
      traitId1: field["TRAIT 1"]?.trim(),
      traitId2: field["TRAIT 2"]?.trim(),
    };

    return preparePlayer(player);
  });

  return playerData;
};

const preparePlayer = (player: any) => {
  const primaryPosition = CREATE_ALL_POSITIONS.findIndex((val) => {
    return val === player.primaryPosition;
  });
  const secondaryPosition = CREATE_ALL_POSITIONS.findIndex(
    (val) => val === player.secondaryPosition
  );

  const pitcherRole = +primaryPosition > 12 ? +primaryPosition : undefined;
  const bats = ALL_BATS.findIndex((i) => i === player.bats);
  const throws = ALL_THROWS.findIndex((i) => i === player.throws);
  const ranking = RATING_MAP.findIndex((i) => i === player.ranking);
  const playerChemistry = CHEMISTRY_MAP[player.playerChemistry.toLowerCase()];
  const traitId1 = ALL_TRAITS.find(
    (i) => flattenName(i) === flattenName(player.traitId1)
  );
  const traitId2 = ALL_TRAITS.find(
    (i) => flattenName(i) === flattenName(player.traitId2)
  );

  return {
    ...player,
    primaryPosition: +primaryPosition > 12 ? 0 : primaryPosition,
    secondaryPosition:
      secondaryPosition < 0
        ? CREATE_ALL_POSITIONS.length - 1
        : secondaryPosition,
    pitcherRole,
    bats: bats > -1 ? bats : 0,
    throws: throws > -1 ? throws : 0,
    ranking,
    playerChemistry,
    traitId1: traitId1 ?? undefined,
    traitId2: traitId2 ?? undefined,
  };
};

const flattenName = (name?: string) => {
  if (!name) {
    return "";
  }
  return name.replace(/[\s']/gi, "").toLowerCase();
};
const nameMatching = (playerName: string, matchName: string) => {
  const flatPlayer = flattenName(playerName);
  const flatMatch = flattenName(matchName);

  const isEqual = flatPlayer === flatMatch;
  if (isEqual) {
    return true;
  }
  const isPlayerIncludeMatch = flatPlayer.includes(flatMatch);
  if (isPlayerIncludeMatch) {
    return true;
  }

  const isMatchInclude = flatMatch.includes(flatPlayer);
  if (isMatchInclude) {
    return true;
  }
  return false;
};

export const extractPlayerInfo = (data: any) => {
  const splitContent = data.readResult?.pages?.[0]?.lines;

  if (!splitContent) {
    return "";
  }

  const jerseyNumber = splitContent[0].content.replace("#", "").trim();
  const [first_name, last_name] = splitContent.slice(-2);
  const firstName = titleCase(first_name?.content?.trim());
  const lastName = titleCase(last_name?.content?.trim());
  const fullName = [firstName, lastName].join(" ");
  return { fullName, jerseyNumber };
};

export const preparePlayerData = (data: any) => {
  const nameImages = data.playerData.map((d: any) => extractPlayerInfo(d));
  const playerAttributes = extractAttributesInfo(data);
  const playerData = playerAttributes.map((player: any) => {
    const photoIdx = nameImages.findIndex(({ fullName }) =>
      nameMatching(player.fullName, fullName)
    );
    player.photoIdx = photoIdx !== undefined && photoIdx > -1 ? photoIdx : null;
    player.jerseyNumber = nameImages?.[photoIdx]?.jerseyNumber || 0;
    return player;
  });

  return { ...data, playerData };
};

export const prepareStats = (data) => {
  const allStats = data.map((d) => {
    const headers = d.tables[0].cells
      .filter(({ kind }) => kind === "columnHeader")
      .map(({ content }) => content);

    const stats = d.tables[0].cells.reduce((acc, cell) => {
      if (cell.kind === "columnHeader") {
        return acc;
      }

      if (!acc[cell.rowIndex]) {
        acc[cell.rowIndex] = {};
      }

      acc[cell.rowIndex][headers[cell.columnIndex]] = cell.content;
      return acc;
    }, {});
    return Object.values(stats);
  });

  return allStats.reduce(
    (acc, statsFile) => {
      const isPitching = statsFile.length === 10;
      statsFile.forEach((row) => {
        const nameSlug = flattenName(row.Name);
        row.fullName = row.Name;
        row.playerSlug = nameSlug;
        if (!acc[nameSlug]) {
          acc[nameSlug] = { fielding: {}, pitching: {} };
        }

        if (isPitching) {
          acc[nameSlug].pitching = Object.assign(acc[nameSlug].pitching, row);
        } else {
          acc[nameSlug].fielding = Object.assign(acc[nameSlug].fielding, row);
        }
      });
      return acc;
    },
    { localId: undefined }
  );
};

export const changeItem = (prev: TFilter[], value: TFilter) => {
  const cPrev = [...prev];
  const findName = cPrev.find((i) => i.name === value.name);
  if (findName) {
    findName.checked = value.checked;
  }
  return cPrev;
};
