import axios from "axios";
import { BASE_URL } from "../constants";
import { preparePlayerData } from "../utilities/helpers";
import { useAppContext } from "../AppContext";

const useUploadImages = () => {
  const { tokens } = useAppContext();

  const uploadTeamAttributes = async (
    formData: any,
    teamDirectory?: string
  ) => {
    const { data } = await axios({
      method: "POST",
      url: `${BASE_URL}/team/team_attributes?teamDirectory=${teamDirectory}`,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${tokens.accessToken}`,
      },
    });

    return preparePlayerData(data);
  };

  return { uploadTeamAttributes };
};

export default useUploadImages;
