import {
  getSkillColor,
  SFlex,
  SSkillCell,
  SSkillCellInner,
  SSkillCellText,
} from "../styles";

const SkillCell = ({
  value,
  hideValue,
  style,
}: {
  value: number;
  hideValue?: boolean;
  style?: any;
}) => {
  const color = getSkillColor(value);
  return (
    <SFlex style={{ position: "relative", ...style }}>
      <SSkillCell>
        <SSkillCellInner width={value} color={color} />
      </SSkillCell>
      {!hideValue && <SSkillCellText>{value}</SSkillCellText>}
    </SFlex>
  );
};

export default SkillCell;
