export const rootRoute = "/";

const USER = "user";
const TRAITS = "traits";
const TEAMS = "teams";
const STATS = "stats";
const PLAYER = "player";

export const userRoute = `/${USER}`;
export const profileRoute = `${userRoute}/profile`;
export const registerRoute = `${userRoute}/register`;
export const loginRoute = `${userRoute}/login`;

export const userTeamsRoute = `${profileRoute}/${TEAMS}`;
export const userTeamViewRoute = `${userTeamsRoute}/:teamSlug`;
export const userTeamStatsRoute = `${userTeamsRoute}/:teamSlug/${STATS}`;
export const userTeamEditRoute = `${userTeamsRoute}/:teamSlug/edit`;
export const userTeamCreateRoute = `${userTeamsRoute}/create`;

export const traitsRoute = `/${TRAITS}`;
export const teamsRoute = `/${TEAMS}`;
export const teamViewPath = `${teamsRoute}/:teamSlug`;
export const playerPath = `${PLAYER}/:playerSlug`;
export const fieldPlayerRoute = `/players`;
export const pitcherRoute = `/pitchers`;

const SHARED = "shared_teams";
export const sharedTeamsRoute = `/${SHARED}`;
export const sharedTeamViewRoute = `${sharedTeamsRoute}/:teamDirectory`;
