export const CANONICAL_URL = "https://reallyepichardball.com";
export const FREE_AGENT_TEAM = "Free Agent";
export const MAX_PLAYERS = 22;
export const SESSION_VALUE = "session";
export const BASE_URL =
  process.env.NODE_ENV === "production"
    ? `https://${window.location.hostname}/api`
    : `http://${window.location.hostname}:5005`;

const IMAGE_URL = `${BASE_URL}/images`;
const IMAGE_URL_AWS = `https://smb4.s3.us-west-2.amazonaws.com`;
export const IMAGE_BASE_URL =
  process.env.NODE_ENV === "production" ? IMAGE_URL_AWS : IMAGE_URL;
export const BLANK_PLAYER_IMG = `${IMAGE_BASE_URL}/blankPlayer.png`;
export const BLANK_TEAM_IMG = `${IMAGE_BASE_URL}/blankTeam.png`;

export const RATING_MAP = [
  "S",
  "A+",
  "A",
  "A-",
  "B+",
  "B",
  "B-",
  "C+",
  "C",
  "C-",
  "D+",
  "D",
  "D-",
];

export const CREATE_ALL_POSITIONS = [
  "P",
  "C",
  "1B",
  "2B",
  "3B",
  "SS",
  "LF",
  "CF",
  "RF",
  "IF",
  "OF",
  "1B/OF",
  "IF/OF",
  "SP",
  "SP/RP",
  "RP",
  "CP",
  "None",
];

export const FILTER_RATING_MAP = ["S", "A", "B", "C", "D"];

export const SKILLS = {
  accuracy: "accuracy",
  age: "age",
  arm: "arm",
  arsenal: "arsenal",
  bats: "bats",
  careerEnd: "careerEnd",
  careerStart: "careerStart",
  changeup: "changeup",
  contact: "contact",
  curveball: "curveball",
  cutFastball: "cutFastball",
  fielding: "fielding",
  firstName: "firstName",
  forkball: "forkball",
  fourSeamFastball: "fourSeamFastball",
  fullName: "fullName",
  gender: "gender",
  jerseyNumber: "jerseyNumber",
  junk: "junk",
  lastName: "lastName",
  league: "league",
  leagueId: "leagueId",
  localID: "localID",
  pitchAngle: "pitchAngle",
  pitcherRole: "pitcherRole",
  pitcherRoleShort: "pitcherRoleShort",
  photoIdx: "photoIdx",
  playerChemistry: "playerChemistry",
  power: "power",
  primaryPosition: "primaryPosition",
  primaryPositionShort: "primaryPositionShort",
  ranking: "ranking",
  rating: "rating",
  salary: "salary",
  screwball: "screwball",
  secondaryPosition: "secondaryPosition",
  secondaryPositionShort: "secondaryPositionShort",
  slider: "slider",
  speed: "speed",
  team: "team",
  teamId: "teamId",
  teamSlug: "teamSlug",
  throws: "throws",
  trait1: "trait1",
  trait2: "trait2",
  traitId1: "traitId1",
  traitId2: "traitId2",
  twoSeamFastball: "twoSeamFastball",
  velocity: "velocity",
  windup: "windup",
};

export const PLAYER_ATTRIBUTES: string[] = [
  SKILLS.team,
  SKILLS.league,
  SKILLS.fullName,
  SKILLS.ranking,
  SKILLS.primaryPositionShort,
  SKILLS.secondaryPositionShort,
  SKILLS.power,
  SKILLS.contact,
  SKILLS.speed,
  SKILLS.fielding,
  SKILLS.arm,
  SKILLS.bats,
  SKILLS.throws,
  SKILLS.age,
  SKILLS.playerChemistry,
  SKILLS.trait1,
  SKILLS.trait2,
];

export const PITCHER_ATTRIBUTES: string[] = [
  SKILLS.team,
  SKILLS.league,
  SKILLS.fullName,
  SKILLS.ranking,
  SKILLS.pitcherRoleShort,
  SKILLS.velocity,
  SKILLS.junk,
  SKILLS.accuracy,
  SKILLS.arsenal,
  SKILLS.throws,
  SKILLS.age,
  SKILLS.playerChemistry,
  SKILLS.trait1,
  SKILLS.trait2,
];

export const NO_SORT_COLUMNS = [SKILLS.arsenal];

// for columns with abbreviated (short) names
export const playerColumnSort = {
  [SKILLS.team]: SKILLS.teamId,
  [SKILLS.league]: SKILLS.leagueId,
  [SKILLS.trait1]: SKILLS.traitId1,
  [SKILLS.trait2]: SKILLS.traitId2,
  [SKILLS.fullName]: SKILLS.firstName,
  [SKILLS.primaryPositionShort]: SKILLS.primaryPosition,
  [SKILLS.secondaryPositionShort]: SKILLS.secondaryPosition,
  [SKILLS.pitcherRoleShort]: SKILLS.pitcherRole,
};

export const PITCH_TYPE: { [key: string]: { short: string; full: string } } = {
  fourSeamFastball: { short: "4F", full: "4-seam fastball" },
  twoSeamFastball: { short: "2F", full: "2-seam fastball" },
  cutFastball: { short: "CF", full: "Cut fastball" },
  curveball: { short: "CB", full: "Curveball" },
  slider: { short: "SL", full: "Slider" },
  screwball: { short: "SB", full: "Screwball" },
  changeup: { short: "CH", full: "Change up" },
  forkball: { short: "FK", full: "Forkball" },
};

export const tableHeaders: { [key: string]: string } = {
  [SKILLS.team]: "team",
  [SKILLS.fullName]: "name",
  [SKILLS.primaryPositionShort]: "pos",
  [SKILLS.secondaryPositionShort]: "pos2",
  [SKILLS.rating]: "rating",
  [SKILLS.power]: "pow",
  [SKILLS.contact]: "con",
  [SKILLS.speed]: "spd",
  [SKILLS.fielding]: "fld",
  [SKILLS.arm]: "arm",
  [SKILLS.bats]: "bat",
  [SKILLS.throws]: "thr",
  [SKILLS.age]: "age",
  [SKILLS.ranking]: "rank",
  [SKILLS.pitcherRoleShort]: "role",
  [SKILLS.arsenal]: "arsenal",
  [SKILLS.velocity]: "vel",
  [SKILLS.junk]: "jnk",
  [SKILLS.accuracy]: "acc",
  [SKILLS.trait1]: "trait",
  [SKILLS.trait2]: "trait 2",
  [SKILLS.jerseyNumber]: "#",
  [SKILLS.playerChemistry]: "chem",
  [SKILLS.careerStart]: "strt",
  [SKILLS.salary]: "$",
};

export const centeredColumns: string[] = [
  SKILLS.primaryPositionShort,
  SKILLS.secondaryPositionShort,
  SKILLS.power,
  SKILLS.contact,
  SKILLS.ranking,
  SKILLS.speed,
  SKILLS.fielding,
  SKILLS.arm,
  SKILLS.bats,
  SKILLS.throws,
  SKILLS.age,
  SKILLS.arsenal,
  SKILLS.pitcherRoleShort,
  SKILLS.velocity,
  SKILLS.junk,
  SKILLS.accuracy,
  SKILLS.league,
];

export const imageColumns = {
  [SKILLS.playerChemistry]: `${IMAGE_BASE_URL}/chemistry/player/`,
  trait: `${IMAGE_BASE_URL}/chemistry/trait/`,
  [SKILLS.trait1]: `${IMAGE_BASE_URL}/chemistry/trait/`,
  [SKILLS.trait2]: `${IMAGE_BASE_URL}/chemistry/trait/`,
  [SKILLS.league]: `${IMAGE_BASE_URL}/leagues/`,
  [SKILLS.teamSlug]: `${IMAGE_BASE_URL}/teams/`,
  playerImage: `${IMAGE_BASE_URL}/players/`,
};

export const numberColumns: string[] = [
  SKILLS.power,
  SKILLS.contact,
  SKILLS.speed,
  SKILLS.fielding,
  SKILLS.arm,
  SKILLS.velocity,
  SKILLS.junk,
  SKILLS.accuracy,
];

export const ALL_GENDER = ["M", "F"];
export const ALL_BATS = ["R", "L", "S"];
export const ALL_THROWS = ["R", "L"];
export const ALL_LEAGUE = ["superMega", "legends", "creators"];
export const ALL_PITCHING = ["SP", "SP/RP", "RP", "CP"];
export const ALL_POSITIONS = [
  "C",
  "1B",
  "2B",
  "3B",
  "SS",
  "LF",
  "CF",
  "RF",
  "P",
];
export const SECOND_POSITIONS = [
  "None",
  ...ALL_POSITIONS.slice(0, -1), // remove "P"
  "IF",
  "OF",
  "1B/OF",
  "IF/OF",
];
export const ALL_PITCHES = Object.keys(PITCH_TYPE).map((pitchKey) => {
  return PITCH_TYPE[pitchKey].short;
});

export const ALL_CHEMISTRY = [
  "Competitive",
  "Crafty",
  "Disciplined",
  "Scholarly",
  "Spirited",
];

export const CHEMISTRY_MAP: { [key: string]: string } = {
  cmp: "Competitive",
  cra: "Crafty",
  dis: "Disciplined",
  sch: "Scholarly",
  spi: "Spirited",
};

export const ALL_TEAMS = [
  "Free Agent",
  "Beewolves",
  "Blowfish",
  "Boomers",
  "Buzzards",
  "Can O' Corns",
  "Crocodons",
  "Empire",
  "Freebooters",
  "Girls & Co.",
  "Goat Herd",
  "Grapplers",
  "Heaters",
  "Herbisaurs",
  "Hot Corners",
  "Jacks",
  "Jomboy Media",
  "Joyriders",
  "Mammotanks",
  "Mashing Monsters",
  "Moonstars",
  "Moose",
  "Nemesis",
  "Originators",
  "Overdogs",
  "Pinstripe Strong",
  "Platypi",
  "Prime Time",
  "Rakers",
  "Sand Cats",
  "Sawteeth",
  "Sirloins",
  "Spirit",
  "Thrillers",
  "We Got Ice",
  "Wideloads",
  "Wild Pigs",
];

const SHARED_TRAITS = [
  "None",
  "Two Way (C)",
  "Two Way (IF)",
  "Two Way (OF)",
  "Volatile",
  "Injury Prone",
  "Durable",
  "Consistent",
  "Choker",
  "Clutch",
];

export const PITCHING_TRAITS = [
  "BB Prone",
  "Composed",
  "Crossed Up",
  "Elite 2F",
  "Elite 4F",
  "Elite CB",
  "Elite CF",
  "Elite CH",
  "Elite FK",
  "Elite SB",
  "Elite SL",
  "Easy Jumps",
  "Falls Behind",
  "Gets Ahead",
  "K Collector",
  "K Neglecter",
  "Meltdown",
  "Metal Head",
  "Pick Officer",
  "Rally Stopper",
  "Reverse Splits",
  "Specialist",
  "Surrounded",
  "Wild Thing",
  "Workhorse",
];

export const HITTER_TRAITS = [
  "Ace Exterminator",
  "Bad Ball Hitter",
  "Bad Jumps",
  "Base Jogger",
  "Base Rounder",
  "Big Hack",
  "Bunter",
  "Butterfingers",
  "Cannon Arm",
  "CON vs LHP",
  "CON vs RHP",
  "Distractor",
  "Dive Wizard",
  "Easy Target",
  "Fastball Hitter",
  "First Pitch Prayer",
  "First Pitch Slayer",
  "High Pitch",
  "Inside Pitch",
  "Little Hack",
  "Low Pitch",
  "Magic Hands",
  "Mind Gamer",
  "Noodle Arm",
  "Off-speed Hitter",
  "Outside Pitch",
  "Pinch perfect",
  "POW vs LHP",
  "POW vs RHP",
  "Rally Starter",
  "RBI Hero",
  "RBI Zero",
  "Sign Stealer",
  "Slow Poke",
  "Sprinter",
  "Stealer",
  "Stimulated",
  "Tough Out",
  "Utility",
  "Whiffer",
  "Wild Thrower",
];

export const ALL_TRAITS = [
  ...SHARED_TRAITS,
  ...PITCHING_TRAITS,
  ...HITTER_TRAITS,
];

export const PLAYER_ATTR = [
  SKILLS.power,
  SKILLS.contact,
  SKILLS.speed,
  SKILLS.fielding,
  SKILLS.arm,
];

export const EXAMPLE_IMG_TITLES = [
  "Player Positions",
  "Fielder Stats",
  "Pitcher Stats",
  "Player Traits",
  "Player cards grid",
  "Team logo screen",
];

export const PITCHER_ATTR = [SKILLS.velocity, SKILLS.junk, SKILLS.accuracy];

export const FIELDING_STATS = [
  "Name",
  "POS",
  "G",
  "AB",
  "H",
  "HR",
  "RBI",
  "AVG",
  "OBP",
  "SLG",
  "OPS",
  "R",
  "TB",
  "2B",
  "3B",
  "BB",
  "SO",
  "SB",
  "CS",
  "HBP",
  "SAC",
  "SF",
  "E",
  "PB",
  "XBH",
  "PA",
  "BABIP",
  "AB/HR",
  "S0%",
  "XBH%",
  "BB%",
];

export const PITCHING_STATS = [
  "Name",
  "POS",
  "W",
  "L",
  "ERA",
  "R",
  "ER",
  "WHIP",
  "G",
  "GS",
  "SV",
  "IP",
  "H",
  "AVG",
  "SO",
  "BB",
  "WP",
  "HR",
  "CG",
  "SHO",
  "HB",
  "TBF",
  "NP",
  "WPCT",
  "OBP",
  "SO/9",
  "BB/9",
  "H/9",
  "SO/BB",
  "P/IP",
];
