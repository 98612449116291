import { createSelector } from "@reduxjs/toolkit";
import { TGlobalState } from "../stateTypes";

const genderFiltersSelector = ({ filters }: TGlobalState) => filters.gender;
const batsFiltersSelector = ({ filters }: TGlobalState) => filters.bats;
const throwsFiltersSelector = ({ filters }: TGlobalState) => filters.throws;
const leagueFiltersSelector = ({ filters }: TGlobalState) => filters.league;
const positionFiltersSelector = ({ filters }: TGlobalState) => filters.position;
const pitchingFiltersSelector = ({ filters }: TGlobalState) => filters.pitching;
const secondPositionFiltersSelector = ({ filters }: TGlobalState) =>
  filters.secondPosition;
const pitchesFiltersSelector = ({ filters }: TGlobalState) => filters.pitches;
const traitsFiltersSelector = ({ filters }: TGlobalState) => filters.traits;
const traits2FiltersSelector = ({ filters }: TGlobalState) => filters.traits2;
const playerChemistryFiltersSelector = ({ filters }: TGlobalState) =>
  filters.playerChemistry;
const teamsFiltersSelector = ({ filters }: TGlobalState) => filters.teams;
const rankingFiltersSelector = ({ filters }: TGlobalState) => filters.ranking;
const nameFilterSelector = ({ filters }: TGlobalState) => filters.nameFilter;

export const filtersSelector = createSelector(
  [
    genderFiltersSelector,
    batsFiltersSelector,
    throwsFiltersSelector,
    leagueFiltersSelector,
    positionFiltersSelector,
    pitchingFiltersSelector,
    secondPositionFiltersSelector,
    pitchesFiltersSelector,
    traitsFiltersSelector,
    traits2FiltersSelector,
    playerChemistryFiltersSelector,
    teamsFiltersSelector,
    rankingFiltersSelector,
    nameFilterSelector,
  ],
  (
    gender,
    bats,
    throws,
    league,
    position,
    pitching,
    secondPosition,
    pitches,
    traits,
    traits2,
    playerChemistry,
    teams,
    ranking,
    nameFilter
  ) => ({
    filters: {
      gender,
      bats,
      throws,
      league,
      position,
      pitching,
      secondPosition,
      pitches,
      traits,
      traits2,
      playerChemistry,
      teams,
      ranking,
    },
    nameFilter,
  })
);
