import { SFlex } from "../../../styles";
import { TCreateInput } from "../../../types";

const CreateSelect = ({
  inputItem,
  options,
  handleChange,
  value,
}: {
  inputItem: TCreateInput;
  options: string[];
  handleChange: (event: any) => void;
  value: string;
}) => {
  return (
    <SFlex direction="column">
      <label>{inputItem.label}</label>
      <select name={inputItem.attribute} value={value} onChange={handleChange}>
        {options.map((pos: string, idx: number) => (
          <option
            key={`position-${pos}`}
            value={inputItem.type === "text" ? pos : idx}
          >
            {pos}
          </option>
        ))}
      </select>
    </SFlex>
  );
};
export default CreateSelect;
