import { tableHeaders } from "../../constants";
import { SBox, SFlex } from "../../styles";
import { IPlayer } from "../../types";
import SkillCell from "../SkillCell";

const Ability = ({
  player,
  attribute,
}: {
  player: IPlayer;
  attribute: string;
}) => {
  return (
    <SFlex gap="8px" $alignItems="center">
      <SBox>{tableHeaders[attribute].toUpperCase()}</SBox>
      <SBox width="80px" style={{ textAlign: "center" }}>
        <SkillCell value={+player[attribute]} />
      </SBox>
    </SFlex>
  );
};

export default Ability;
