import { useEffect, useState } from "react";
import { SImage } from "../styles";
import { loadImageAsync } from "../utilities/helpers";

const Image = ({
  src,
  alt,
  title,
  style,
}: {
  src: string;
  alt?: string;
  title?: string;
  style?: any;
}) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const load = async () => {
    await loadImageAsync(src);
    setIsImageLoaded(true);
  };

  useEffect(() => {
    load();
  }, [src]);

  return (
    <div style={style} className="img-component">
      <SImage
        src={
          isImageLoaded
            ? src
            : `${process.env.PUBLIC_URL}/baseball_darkload.png`
        }
        alt={alt || String(Math.random() * 1000)}
        title={title}
      />
    </div>
  );
};

export default Image;
